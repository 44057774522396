import React, { useState } from 'react'
import { Button } from '@mui/material';
import { toast } from 'react-toastify';
import NewTicketModal from '../NewTicketModal';

const PhoneLinkModal = ({ number , onClose}) => {
    const [modalOpen, setModalOpen] = useState(false);
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10, margin: 10 }}>

            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    setModalOpen(true)
                }}>
                Iniciar Conversa
            </Button>
            <Button
                variant="contained"
                color="primary"
                onClick={() => {
                    navigator.clipboard.writeText(number).then(() => {
                        toast.success('Número copiado para a área de transferência');
                        onClose()

                    }).catch(err => {
                        console.error('Erro ao copiar o número: ', err);
                    });
                }}>
                Copiar Numero
            </Button>


            {modalOpen ?
                <NewTicketModal modalOpen={modalOpen} initialNumber={number} onClose={() => {
                    setModalOpen(false)
                    onClose()
                }} /> : null}
        </div>
    )
}

export default PhoneLinkModal;