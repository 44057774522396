import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  styled,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import { isArray } from "lodash";
import MainHeader from "../MainHeader";
import Title from "../Title";
import CopyToClipBoard from "../CopyToClipBoard";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  form: {
    width: "100%",
  },
  panel: {
    margin: 8,
  },
  token: {
    margin: "auto",
    width: "50%",
  },
  button: {
    width: "50%",
    margin: 2,
    textAlign: "center",
    fontSize: "20px",
    height: "40px",
    paddingTop: 5,
    borderRadius: 9,
  },
  buttonLarge: {
    width: "100%",
    marginTop: 3,
    textAlign: "center",
    fontSize: "20px",
    height: "40px",
    paddingTop: 5,
    borderRadius: 9,
  },
}));

const StyledInput = styled(TextField)(() => ({
  minWidth: "180px !important",
  marginRight: "10px",
  "& label": { fontSize: "14px" },
  "& label.MuiInputLabel-shrink": { marginTop: "0px" },
  "& .MuiOutlinedInput-root": {
    "& .MuiOutlinedInput-input": {
      fontSize: "14px",
      padding: "10px 14px !important",
    },
  },
}));

const ApiTokenModal = ({ open, onClose, apiId, reload }) => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const initialState = {
    id: null,
    name: "",
    signature: "",
    ticketSetStatus: "closed",
    typeToken: "sendMessage",
    webhookUrl: "",
    webhookToken: "",
    configToken: "",
    timeOutTicketReOpen: 0,
    signature: "📩 Envio automático:",
    queueId: -1,
  };

  const initialQueue = { id: "", name: "" };
  const initialUser = { id: "", name: "" };
  const initialConn = { id: "", name: "" };

  const [apiToken, setApiToken] = useState(initialState);
  const [currentQueue, setCurrentQueue] = useState(initialQueue);
  const [queues, setQueues] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState(initialUser);
  const [connections, setConnections] = useState([]);
  const [currentConn, setCurrentConn] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { data: userData } = await api.get("/users");
        setUsers([{ id: -1, name: "Não atribuir a Usuário" }, ...userData.users]);

        const { data: connectionData } = await api.get("/whatsapp");
        setConnections(connectionData);

        const { data: queueList } = await api.get("/queue");
        const customQueueList = queueList.map((c) => ({ id: c.id, name: c.name }));
        setQueues([...customQueueList]);

        if (apiId) {
          const { data } = await api.get(`/api-token/${apiId}`);
          setCurrentQueue(data.queue);
          setCurrentUser(data.userId);
          setCurrentConn(data.whatsapp);
          setApiToken(data);
        }
      } catch (err) {
        toastError(err);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open, apiId]);

  const handleClose = () => {
    onClose();
    setApiToken(initialState);
    setCurrentQueue(initialQueue);
    setCurrentUser(initialUser);
    setCurrentConn(initialConn);
  };

  const handleSubmitForm = async (values) => {
    if (currentConn.length === 0) {
      toast.warning(i18n.t("É necessário adicionar uma conexão para realizar campanhas"));
      return;
    }

    const apiTokenData = {
      ...values,
      companyId: user.companyId,
      whatsappId: currentConn?.id,
    };

    try {
      if (apiId) {
        await api.put(`/api-token/${apiId}`, apiTokenData);
      } else {
        await api.post(`/api-token/`, apiTokenData);
      }
      toast.success(i18n.t("apiTokenModal.success"));
      if (typeof reload === "function") reload();
    } catch (err) {
      toastError(err);
    }

    handleClose();
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth scroll="paper">
        <div className={classes.panel}>
          <Formik
            initialValues={apiToken}
            enableReinitialize
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSubmitForm(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ isSubmitting, values }) => (
              <>
                <MainHeader>
                  <Title>{i18n.t("Configuração de Token de envio de mensagen")}</Title>
                </MainHeader>
                <div className={classes.token}>
                  {apiToken.id && <h3>Token: <CopyToClipBoard text={apiToken.configToken} /></h3>}
                </div>
                <Form className={classes.form}>
                  <DialogContent dividers>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <InputLabel id="api.modal.typeToken">
                            {i18n.t("api.modal.typeToken.label")}
                          </InputLabel>
                          <Field
                            as={Select}
                            label={i18n.t("api.modal.typeToken.label")}
                            name="typeToken"
                            labelId="api.modal.typeToken.label"
                            required
                            value={values.typeToken}
                            onChange={(e) => setApiToken({ ...apiToken, typeToken: e.target.value })}
                          >
                            <MenuItem value="sendMessage">
                              {i18n.t("api.modal.typeToken.sendMessage")}
                            </MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <Field
                            as={TextField}
                            size="small"
                            name="name"
                            label="Nome Identificador da Api"
                            variant="outlined"
                            placeholder="Envio de relatórios financeiros"
                            required
                            value={apiToken.name}
                            onChange={(e) => setApiToken({ ...apiToken, name: e.target.value })}
                            error={apiToken.name === ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <Field
                            as={TextField}
                            size="small"
                            name="configToken"
                            label="Token de configuração"
                            variant="outlined"
                            placeholder="Token de configuração"
                            value={apiToken.configToken}
                            onChange={(e) => setApiToken({ ...apiToken, configToken: e.target.value })}
                            error={apiToken.configToken === ""}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <InputLabel id="ticketSetStatus-selection-input-label">
                            {i18n.t("Status do ticket após envio")}
                          </InputLabel>
                          <Field
                            as={Select}
                            label={i18n.t("Status do ticket após envio")}
                            name="ticketSetStatus"
                            labelId="ticketSetStatus-selection-label"
                            required
                            value={values.ticketSetStatus}
                            onChange={(e) => setApiToken({ ...apiToken, ticketSetStatus: e.target.value })}
                            error={apiToken.ticketSetStatus === ""}
                          >
                            <MenuItem value="open">{i18n.t("api.status.open")}</MenuItem>
                            <MenuItem value="pending">{i18n.t("api.status.pending")}</MenuItem>
                            <MenuItem value="closed">{i18n.t("api.status.closed")}</MenuItem>
                            <MenuItem value="destroy">{i18n.t("api.status.destroy")}</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>

                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Autocomplete
                            size="small"
                            options={connections}
                            value={currentConn}
                            onChange={(e, newValue) => {
                              setCurrentConn(newValue || "");
                            }}
                            getOptionLabel={(option) => option.name || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Conexão"
                                variant="outlined"
                                placeholder="Selecione conexões para o disparo das mensagens"
                                required
                                error={currentConn.length === 0}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" fullWidth>
                          <Autocomplete
                            size="small"
                            options={queues}
                            value={currentQueue}
                            onChange={(e, queue) => {
                              setCurrentQueue(queue || initialQueue);
                              setApiToken({ ...apiToken, queueId: queue?.id || "" });
                            }}
                            getOptionLabel={(option) => option.name || ""}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Fila"
                                variant="outlined"
                                placeholder="Fila"
                                required
                                error={apiToken.queueId === -1}
                              />
                            )}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl style={{paddingTop: "8px"}} variant="outlined" fullWidth>
                          <StyledInput
                          fullWidth
                          variant="outlined"
                            select
                            label="Usuário"
                            size="small"
                            name="userId"
                            value={currentUser}
                            onChange={(e) => {
                              setCurrentUser(e.target.value);
                              setApiToken({ ...apiToken, userId: e.target.value });
                            }}
                          >
                            {users.map((user) => (
                              <MenuItem key={user.id} value={user.id}>
                                {user.name}
                              </MenuItem>
                            ))}
                          </StyledInput>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <Field
                            as={TextField}
                            size="small"
                            name="signature"
                            label={i18n.t("api.modal.signature")}
                            variant="outlined"
                            placeholder={i18n.t("api.modal.signature")}
                            value={apiToken.signature}
                            onChange={(e) => setApiToken({ ...apiToken, signature: e.target.value })}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <Field
                            as={TextField}
                            size="small"
                            name="webhookUrl"
                            label="Webhook Url"
                            variant="outlined"
                            placeholder="Webhook Url"
                            value={apiToken.webhookUrl}
                            onChange={(e) => setApiToken({ ...apiToken, webhookUrl: e.target.value })}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <Field
                            as={TextField}
                            size="small"
                            name="webhookToken"
                            label="Webhook Token"
                            variant="outlined"
                            placeholder="Webhook Token"
                            value={apiToken.webhookToken}
                            onChange={(e) => setApiToken({ ...apiToken, webhookToken: e.target.value })}
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <InputLabel id="timeOutTicketReOpen">
                            {i18n.t("api.modal.timeOutTicketReOpen")}
                          </InputLabel>
                          <Field
                            as={Select}
                            label={i18n.t("api.modal.timeOutTicketReOpen")}
                            name="timeOutTicketReOpen"
                            labelId="api.modal.timeOutTicketReOpen"
                            value={values.timeOutTicketReOpen}
                            onChange={(e) => setApiToken({ ...apiToken, timeOutTicketReOpen: e.target.value })}
                          >
                            <MenuItem value={0}>{i18n.t("Não reabrir")}</MenuItem>
                            <MenuItem value={60000}>{i18n.t("1 minuto")}</MenuItem>
                            <MenuItem value={300000}>{i18n.t("5 minutos")}</MenuItem>
                            <MenuItem value={600000}>{i18n.t("10 minutos")}</MenuItem>
                            <MenuItem value={1800000}>{i18n.t("30 minutos")}</MenuItem>
                            <MenuItem value={3600000}>{i18n.t("1 hora")}</MenuItem>
                            <MenuItem value={86400000}>{i18n.t("1 dia")}</MenuItem>
                          </Field>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </DialogContent>
   


                    <div>
                      <h5>
                        {apiToken?.userId !== -1 ? 
                        "Regra de recebimento: Quando respondido a mensagem pelo contato e o usuário estiver selecionado o ticket entrara na fila com status atendendo" :
                          "Regra de recebimento: Quando respondido a mensagem pelo contato e o usuário não estiver selecionado o ticket entrara na fila com status aguardando"}
                      </h5>
                      <br />
                      {apiToken?.webhookUrl !== "" && <><h5>Com webhook ativado você pode forçar o fechamento do ticket retornando o response json:</h5>
                        <pre>{`{ "ticket": { "status": "closed" } }`}</pre>
                        <h5>Qualquer outra resposta será ignorada, e o status do ticket será mantido conforme a regra de recebimento acima</h5>

                      </>}
                    </div>

                  <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                      {i18n.t("common.cancel")}
                    </Button>
                    <Button

                      disabled={apiToken.queueId === -1}
                      type="submit" color="primary" variant="contained">
                      {apiId ? i18n.t("common.save") : i18n.t("common.add")}
                      {isSubmitting && <CircularProgress size={24} />}
                    </Button>
                  </DialogActions>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
};

export default ApiTokenModal;
