import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import { makeStyles } from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import useQueues from "../../hooks/useQueues";
import { toast } from "react-toastify";


const useStyles = makeStyles((theme) => ({
	maxWidth: {
		width: "100%",
		padding: 12
	},
}));

const filterOptions = createFilterOptions({
	trim: true,
});

function renderOption(option, { inputValue }) {

	const isOnline = option.userStatus === 'online';
	const backgroundColor = isOnline ? 'green' : 'red';

	return (
		<>
			<div style={{ backgroundColor, width: 14, height: 14, marginRight: 3, borderRadius: 7 }}></div>
			{option.name}
		</>

	);
}

const TransferTicketModal = ({ modalOpen, onClose, ticketid }) => {
	const history = useHistory();
	const [options, setOptions] = useState([]);
	const [queues, setQueues] = useState([]);
	const [allQueues, setAllQueues] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchParam, setSearchParam] = useState("");
	const [selectedUser, setSelectedUser] = useState(null);
	const [selectedQueue, setSelectedQueue] = useState('');
	const classes = useStyles();
	const { findAll: findAllQueues } = useQueues();
	const isMounted = useRef(true);
	const [integrations, setIntegrations] = useState([]);
	const [integrationSelected, setIntegrationSelected] = useState("internal");
	useEffect(() => {
		const fetchIntegrations = async () => {
			try {
				const { data } = await api.get("/integration", {
					params: { integrationType: "sendTransferTicketEchat" },
				});
				console.log(data);
				setIntegrations(data?.integrations);
			} catch (err) {
				toastError(err);
			}
		};

		if (modalOpen) {
			fetchIntegrations();
		}
	}, [modalOpen]);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		}
	}, []);

	const fetchUsers = async () => {
		try {
			const { data } = await api.get("/users/", {
				params: { searchParam },
			});
			setOptions(data.users);
			setLoading(false);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	useEffect(() => {
		if (modalOpen) {
			if (isMounted.current) {
				const loadQueues = async () => {
					const list = await findAllQueues();
					setAllQueues(list);
					setQueues(list);
				}
				loadQueues();
				fetchUsers();
			}

		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [modalOpen]);

	useEffect(() => {
		if (!modalOpen || searchParam.length < 3) {
			setLoading(false);
			return;
		}
		setLoading(true);
		const delayDebounceFn = setTimeout(() => {


			fetchUsers();
		}, 500);
		return () => clearTimeout(delayDebounceFn);
	}, [searchParam, modalOpen]);

	const handleClose = () => {
		onClose();
		setSearchParam("");
		setSelectedUser(null);
		setSelectedQueue("")
	};

	const handleTransferToEchat = async (e) => {
		setLoading(true);
		e.preventDefault();

		try {
			const { data } = await api.post(`/integration-actions`, {
				integrationId: integrationSelected,
			ticketId: ticketid
		});
			toast.success('Ticket transferido com sucesso');
			history.push(`/tickets`);
			handleClose();
			setLoading(false);
		} catch (err) {
			toast.error('Erro ao transferir o ticket');
			setLoading(false);
		}
	}

	const handleSaveTicket = async e => {
		e.preventDefault();
		if (!ticketid) return;
		setLoading(true);
		try {
			let data = { reason: "user-transfer" };

			if (selectedUser) {
				data.userId = selectedUser.id
				data.transf = true;
			}

			if (selectedQueue && selectedQueue !== null) {
				data.queueId = selectedQueue

				if (!selectedUser) {
					data.status = 'pending';
					data.userId = null;
					data.transf = true;
				} else {
					data.status = 'open';
				}
			}

			await api.put(`/tickets/${ticketid}`, data);
			history.push(`/tickets`);
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="sm" scroll="paper">
			<form onSubmit={handleSaveTicket}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("transferTicketModal.title")}
				</DialogTitle>
				<DialogContent dividers>
					{integrations?.length ? <>
						<FormControl variant="outlined" className={classes.maxWidth}>
							<InputLabel 
							style={{paddingTop: 10, paddingLeft: 16}}
							>
								{i18n.t("transferTicketModal.integrationSelected")}</InputLabel>
							<Select
								value={integrationSelected}
								labelWidth={0}
								onChange={(e) => setIntegrationSelected(e.target.value)}
								label={i18n.t("transferTicketModal.integrationSelected")}
								labelId="transfer-type-label"
							>
								<MenuItem value={"internal"}>Transferir para o sistema interno</MenuItem>
								{integrations.map((integration) => (
									<MenuItem key={integration.id} value={integration.id}>
										Transferir para {integration.name}
									</MenuItem>
								))}
							</Select>

						</FormControl>
					</> : null}
					{integrationSelected === "internal" ? <>
						<FormControl variant="outlined" className={classes.maxWidth}>
							<Autocomplete

								getOptionLabel={option => `${option.name}`}
								onChange={(e, newValue) => {
									setSelectedUser(newValue);
									if (newValue != null && Array.isArray(newValue.queues)) {
										setQueues(newValue.queues);
									} else {
										setQueues(allQueues);
										setSelectedQueue('');
									}
								}}
								options={options}
								filterOptions={filterOptions}
								freeSolo
								autoHighlight
								noOptionsText={i18n.t("transferTicketModal.noOptions")}
								loading={loading}
								//renderOption={renderOption}
								renderInput={params => (
									<TextField
										{...params}


										label={i18n.t("transferTicketModal.fieldLabel")}
										variant="outlined"
										autoFocus
										onChange={e => setSearchParam(e.target.value)}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<React.Fragment>
													{loading ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</React.Fragment>
											),
										}}
									/>
								)}
							/>
						</FormControl>

						<FormControl variant="outlined" className={classes.maxWidth}>
							<InputLabel>{i18n.t("transferTicketModal.fieldQueueLabel")}</InputLabel>
							<Select
								value={selectedQueue}
								onChange={(e) => setSelectedQueue(e.target.value)}
								label={i18n.t("transferTicketModal.fieldQueuePlaceholder")}
							>
								<MenuItem value={""}>&nbsp;</MenuItem>
								{queues.map((queue) => (
									<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
								))}
							</Select>
						</FormControl>
					</> : null}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleClose}
						color="secondary"
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("transferTicketModal.buttons.cancel")}
					</Button>
					{integrationSelected === "internal" && <>
						<ButtonWithSpinner
							variant="contained"
							type="submit"
							color="primary"
							loading={loading}
							disabled={selectedQueue === "" ? true : false}
						//disabled = {selectedUser === null ? true : selectedQueue === "" ? true : false}
						>
							{i18n.t("transferTicketModal.buttons.ok")}
						</ButtonWithSpinner>
					</>}
					{integrationSelected !== "internal" && <>
						<ButtonWithSpinner
							onClick={handleTransferToEchat}
							variant="contained"
							type="submit"
							color="primary"
							loading={loading}
						>
							{`Transferir para outro Echat`}
						</ButtonWithSpinner>
					</>}
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default TransferTicketModal;
