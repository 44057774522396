import React, { useState, useRef, useEffect } from 'react';
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api';
import { makeStyles } from '@material-ui/core';
import api from '../../services/api';
import toastError from '../../errors/toastError';
import { TextField } from "@material-ui/core";

const libraries = ['places'];

const useStyles = makeStyles((theme) => ({
  dialog: {
    // the dropdown is next to the dialog root, not inside
    "& + .pac-container": {
      zIndex: theme.zIndex.modal + 1
    }
  }
}));

const LocationOnMaps = ({ onChange, initialMarker, onClose }) => {
  const [map, setMap] = useState(null);
  const [marker, setMarker] = useState(initialMarker);
  const autocompleteRef = useRef(null);
  const [apiToken, setApiToken] = useState("");






  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const { data } = await api.get("/integration", {
          params: { integrationType: "googleMapsApi" },
        });
        setApiToken(data?.integrations[0]?.apiToken || "");
      } catch (err) {
        toastError(err);
      }
    };
    fetchIntegrations();

  }, []);



  useEffect(() => {
    if (onChange) {
      onChange(marker);
    }
  }, [marker]);

  const onLoad = (mapInstance) => {
    setMap(mapInstance);
  };







  const onPlaceChanged = () => {
    const place = autocompleteRef.current.getPlace();
    if (place.geometry) {
      const location = place.geometry.location;
      map.panTo(location);
      setMarker({
        lat: location.lat(),
        lng: location.lng(),
        name: place.name
      });
    }
  };

  const onMapClick = (event) => {
    setMarker({
      ...marker,
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
  };

  return (
    <>
    {apiToken ? (
      <LoadScript googleMapsApiKey={apiToken} libraries={libraries}>
        <Autocomplete onLoad={(ref) => (autocompleteRef.current = ref)} onPlaceChanged={onPlaceChanged}>
          <input type="text" placeholder="Pesquise um endereço" style={{ width: '100%', padding: '10px', fontSize: '16px', boxSizing: 'border-box' }} />
        </Autocomplete>
        <GoogleMap
          onLoad={onLoad}
          onClick={onMapClick}
          mapContainerStyle={{ height: '300px', width: '100%', marginTop: '10px', marginBottom: '16px' }}
          center={{ ...marker }}
          zoom={13}
        >
          {marker && <Marker position={marker} />}
        </GoogleMap>
      </LoadScript>
    ) : (
      <>

          <TextField
            name="location"
            label="Localização"
            placeholder="-26.340417958485848, -48.8809213156802"
            variant="outlined"
            fullWidth
            required
            onChange={(e) => {
              setMarker({ lat: e.target.value.split(',')[0], lng: e.target.value.split(',')[1] });
            }}
          />

      </>
    )}
    </>
  );
};

export default LocationOnMaps;