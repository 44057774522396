import React, { useState, useRef, useEffect, useReducer } from 'react';
import toastError from "../../errors/toastError";

import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
    InputAdornment,
    makeStyles,
    Card,
    CardContent,
    Typography,
    CardActions,
    IconButton
} from "@material-ui/core";
import { Edit, DeleteOutline, SendOutlined } from "@material-ui/icons";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";

import SearchIcon from "@material-ui/icons/Search";
import InfoBadge from '../../components/InfoBadge';
import MainHeader from '../MainHeader';
import Title from '../Title';
import MainHeaderButtonsWrapper from '../MainHeaderButtonsWrapper';
import ConfirmSendModal from './confirmSendModal'; // Added this import
import LocationModal from '../../pages/Location/modal';

const reducer = (state, action) => {
    if (action.type === "LOAD_DATA") {
        const locations = action.payload;
        const newLocations = [];

        locations.forEach((location) => {
            const locationIndex = state.findIndex((q) => q.id === location.id);
            if (locationIndex !== -1) {
                state[locationIndex] = location;
            } else {
                newLocations.push(location);
            }
        });

        return [...state, ...newLocations];
    }

    if (action.type === "UPDATE_FLOW") {
        const location = action.payload;
        const locationIndex = state.findIndex((q) => q.id === location.id);

        if (locationIndex !== -1) {
            state[locationIndex] = location;
            return [...state];
        } else {
            return [location, ...state];
        }
    }

    if (action.type === "DELETE_FLOW") {
        const locationId = action.payload;

        const locationIndex = state.findIndex((q) => q.id === locationId);
        if (locationIndex !== -1) {
            state.splice(locationIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
}


const useStyles = makeStyles((theme) => ({
    dialog: {
        // the dropdown is next to the dialog root, not inside
        "& + .pac-container": {
            zIndex: theme.zIndex.modal + 1
        }
    },
    cardContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    card: {
        marginRight: '1rem',
        marginBottom: '1rem',
    }
}));

const LocationSelectModal = ({ open, onClose, ticketId , quotedMsg}) => {
    const classes = useStyles();
    const [name, setName] = useState("");


    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [deleting, setDeleting] = useState(null);
    const [searchParam, setSearchParam] = useState("");
    const [locations, dispatch] = useReducer(reducer, []);
    const [hasMore, setHasMore] = useState(false);
    const [confirmSendModalOpen, setConfirmSendModalOpen] = useState(false); // Added this state
    const [selectedLocation, setSelectedLocation] = useState(null); // Added this state
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const filteredLocations = locations.filter(location =>
        location.name.toLowerCase().includes(searchParam)
    );

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam]);

    const fetch = async () => {
        console.log("fetching");
        try {
            const { data } = await api.get("/locations/", {
                params: { searchParam, pageNumber },
            });
            console.log(data);
            dispatch({ type: "LOAD_DATA", payload: data.locations });
            setHasMore(data.hasMore);
            setLoading(false);
        } catch (err) {
            console.log(err);
            toastError(err);
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    useEffect(() => {

        const delayDebounceFn = setTimeout(() => {
            fetch();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
        // eslint-disable-next-line
    }, [searchParam, pageNumber]);

    const handleEdit = (locationId) => {
        onClose();
        // Navigate to the edit page for the selected location
    };

    const handleSendClick = (location) => {
        setSelectedLocation(location);
        setConfirmSendModalOpen(true);
    };


    const handelLocationSavedData = async (e) => {
        await new Promise(resolve => setTimeout(resolve, 1000));
        if (e?.name) {
            setSearchParam(e.name.toLowerCase());
        }
    };

    return (<>
        {(open && !loading) ? (
            <Dialog fullWidth maxWidth='md' open={open} onClose={onClose} className={classes.dialog}>
                <div style={{ minHeight: "500px" }}>
                    <DialogTitle>
                        <LocationModal open={locationModalOpen} onClose={() => setLocationModalOpen(false)} locationSavedData={handelLocationSavedData}></LocationModal>
                        <MainHeader>
                            Enviar Localização
                            <InfoBadge
                                infoText="Clique para mais informações"
                                modalTitle="Aqui estão as instruções detalhadas."
                                modalContent={<>
                                    <h3>Instruções para enviar localização</h3>
                                    <ol>
                                        <li>Digite o nome da localização.</li>
                                        <li>Apos encontrar a localização, clique em enviar. <SendOutlined /></li>
                                        <li>Confira se a localização está correta.</li>
                                        <li>Amplie o mapa se necessário.</li>
                                        <li>O Ponto Vermelho é a localização exata.</li>
                                        <li>Clique no botão "Enviar" para enviar a localização.</li>
                                    </ol></>}
                            />
                            <MainHeaderButtonsWrapper>
                                <TextField
                                    placeholder={i18n.t("common.search")}
                                    type="search"
                                    value={searchParam}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: "gray" }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                                <Button variant="contained" color="primary" onClick={onClose}>
                                    {i18n.t("common.goBack")}
                                </Button>
                            </MainHeaderButtonsWrapper>
                        </MainHeader>
                    </DialogTitle>

                    <DialogContent dividers>
                        <div className={classes.cardContainer}>
                            {filteredLocations.length === 0 && <>
                                <Button variant="contained" color="primary" onClick={() => setLocationModalOpen(true)}>
                                    {i18n.t("location.buttons.add")}
                                </Button>
                            </>}
                            {filteredLocations.map((location) => (
                                <Card key={location.id} className={classes.card} style={{ width: '25%' }}>
                                    <CardContent>
                                        <Typography variant="h6" component="h2">
                                            {location.name}
                                        </Typography>

                                        <iframe
                                            src={`https://maps.google.com/maps?q=${location.latitude},${location.longitude}&z=15&output=embed`}
                                            width="100%"
                                            height="200px"
                                            frameBorder="0"
                                            style={{ border: 0 }}
                                            allowFullScreen
                                        ></iframe>
                                    </CardContent>
                                    <CardActions>
                                        <IconButton size="small" onClick={() => handleSendClick(location)}>
                                            <SendOutlined />
                                        </IconButton>
                                    </CardActions>
                                </Card>
                            ))}
                            {loading && <Typography>Carregando...</Typography>}
                        </div>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>

                </div>

            </Dialog>
        ) : (
            null
        )}
        {confirmSendModalOpen && (
            <ConfirmSendModal
                quotedMsg={quotedMsg}
                ticketId={ticketId}
                open={confirmSendModalOpen}
                onClose={() => setConfirmSendModalOpen(false)}
                onSend={() => {
                    onClose();
                }}
                location={selectedLocation}
            />
        )}
    </>
    )
};

export default LocationSelectModal;