import React from "react"
import { blue } from "@material-ui/core/colors"
import CircularProgress from "@material-ui/core/CircularProgress"




const Loading = ({...props}) => {
    return (
        <CircularProgress style={{color: blue[500], opacity: "70%", position: "absolute", top: "20%", left: "50%"}} {...props} size={props.size || 56} />
    )
}

export default Loading;