import React, { useState, useEffect, useReducer } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import IntegrationModal from "../../components/IntegrationModal";
import CopyToClipBoard from "../../components/CopyToClipBoard";
import SecretDisplayed from "../../components/SecretDisplayed";
const reducer = (state, action) => {
  if (action.type === "LOAD_DATA") {
    const integrations = action.payload;
    const newQuickAnswers = [];

    integrations.forEach((integration) => {
      const integrationIndex = state.findIndex((q) => q.id === integration.id);
      if (integrationIndex !== -1) {
        state[integrationIndex] = integration;
      } else {
        newQuickAnswers.push(integration);
      }
    });

    return [...state, ...newQuickAnswers];
  }

  if (action.type === "UPDATE_FLOW") {
    const integration = action.payload;
    const integrationIndex = state.findIndex((q) => q.id === integration.id);

    if (integrationIndex !== -1) {
      state[integrationIndex] = integration;
      return [...state];
    } else {
      return [integration, ...state];
    }
  }

  if (action.type === "DELETE_FLOW") {
    const integrationId = action.payload;

    const integrationIndex = state.findIndex((q) => q.id === integrationId);
    if (integrationIndex !== -1) {
      state.splice(integrationIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overintegrationY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const Integration = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [integrations, dispatch] = useReducer(reducer, []);
  const [deleting, setDeleting] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [apiId, setIntegrationId] = useState(null);
  const [tokenModalOpen, setTokenModalOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  const fetch = async () => {
    try {
      const { data } = await api.get("/integration", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_DATA", payload: data.integrations });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {

      fetch();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [searchParam, pageNumber]);


  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenModal = () => {
    setTokenModalOpen(true);
    fetch();
  };

  const handleEdit = (integrationId) => {

    setIntegrationId(integrationId)
    setTokenModalOpen(true);
    fetch();

  };

  const handleDelete = async (integrationId) => {
    try {
      await api.delete(`/integration/${integrationId}`);

      toast.success(i18n.t("integration.toasts.deleted"));

    } catch (err) {
      toastError(err);
    }
    setDeleting(null);
    setSearchParam("");
    setPageNumber(1);
    setTimeout(() => {
      dispatch({ type: "RESET" });
      fetch();
    }, 1500);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deleting &&
          `${i18n.t("integration.confirmationModal.deleteTitle")} ${deleting.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDelete(deleting.id)}
      >
        {i18n.t("common.irreversible")}
      </ConfirmationModal>
      <IntegrationModal
        open={tokenModalOpen}
        onClose={() => {
          setTokenModalOpen(false)
          setIntegrationId(null)
          fetch()
        }}
        apiId={apiId}
      //userId={user?.id}
      />
      <MainHeader>
        <Title>{i18n.t("integration.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("common.search")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            {i18n.t("integration.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper + " color-quaternary "}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("integration.table.integrationType")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("integration.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("integration.table.apiToken")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("common.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {integrations.map((integration) => (
                <TableRow key={integration?.id}>
                  <TableCell align="center">
                    {i18n.t(`integration.table.${integration?.integrationType}`)}
                  </TableCell>
                  <TableCell align="center">
                    {integration?.name}
                  </TableCell>
                  <TableCell align="center">
                    <SecretDisplayed text={integration?.apiToken} secret={true} />
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEdit(integration.id)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeleting(integration);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default Integration;
