import React, { useState, useEffect, useReducer, useContext } from "react";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Avatar from "@material-ui/core/Avatar";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import ReactJson from 'react-json-view';
import api from "../../services/api";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import { i18n } from "../../translate/i18n";
import MainContainer from "../../components/MainContainer";
import toastError from "../../errors/toastError";
import { AuthContext } from "../../context/Auth/AuthContext";
import SearchTickets from "./searchTickets";
import DateDisplay from "../../components/DateDisplay";
import CodeIcon from '@material-ui/icons/Code';
import { Link } from 'react-router-dom';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

const reducer = (state, action) => {
    if (action.type === "LOAD_TICKETS") {
        const tickets = action.payload;
        const newTickets = [];

        tickets.forEach((ticket) => {
            const ticketIndex = state.findIndex((t) => t.id === ticket.id);
            if (ticketIndex !== -1) {
                state[ticketIndex] = ticket;
            } else {
                newTickets.push(ticket);
            }
        });

        return [...state, ...newTickets];
    }

    if (action.type === "UPDATE_TICKETS") {
        const ticket = action.payload;
        const ticketIndex = state.findIndex((t) => t.id === ticket.id);

        if (ticketIndex !== -1) {
            state[ticketIndex] = ticket;
            return [...state];
        } else {
            return [ticket, ...state];
        }
    }

    if (action.type === "DELETE_TICKET") {
        const ticketId = action.payload;

        const ticketIndex = state.findIndex((t) => t.id === ticketId);
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        maxHeight: '80vh',
        overflowY: 'auto',
        overflowX: 'auto', // Adicionado para scroll lateral
    },
    mainContainer: {
        height: "93%",
        width: "93%",
    }
}));

const TicketsAdmin = () => {
    const classes = useStyles();
    const { user } = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);

    const [tickets, dispatch] = useReducer(reducer, []);
    const [hasMore, setHasMore] = useState(false);
    const [params, setParams] = useState({});
    const [open, setOpen] = useState(false);
    const [openMessages, setOpenMessages] = useState(false);
    const [selectedTicket, setSelectedTicket] = useState(null);
    const [pauseSocket, setPauseSocket] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentTicket, setCurrentTicket] = useState(null);
    const [messages, setMessages] = useState([]);

    useEffect(() => {
        console.log("pauseSocket >>", pauseSocket);
    }, [pauseSocket]);

    useEffect(() => {
        setLoading(true);
        console.log("params", params);
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async () => {
                if (Object.keys(params).length > 0) {
                    dispatch({ type: "RESET" });
                    setPageNumber(1);
                }
                try {
                    const { data } = await api.get("/tickets-admin", {
                        params: { ...params, pageNumber }
                    });
                    dispatch({ type: "LOAD_TICKETS", payload: data.tickets });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchTickets();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [params, pageNumber]);

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

        socket.on("connect", () => {
            socket.emit("joinNotification")
            socket.emit("joinTickets", "open");
            socket.emit("joinTickets", "pending");
            socket.emit("joinTickets", "closed");
        });
        socket.on("ticket", (data) => {
            console.log("pauseSocket >>", pauseSocket);
            if (!pauseSocket) {
                if (data.action === "update" || data.action === "create") {
                    dispatch({ type: "UPDATE_TICKETS", payload: data.ticket });
                }

                if (data.action === "delete") {
                    dispatch({ type: "DELETE_TICKET", payload: +data.ticketId });
                }
            }
        });

        return () => {
            socket.disconnect();
        };
    }, [pauseSocket]);

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleMessaOpen = (ticket) => {
        setMessages(ticket.messages);
        setOpenMessages(true);
    };
    const handleCloseMessages = () => {
        setMessages([]);
        setOpenMessages(false);
    };

    const handleOpen = (ticket) => {
        setSelectedTicket(ticket);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setSelectedTicket(null);
    };

    const handleMenuClick = (event, ticket) => {
        setAnchorEl(event.currentTarget);
        setCurrentTicket(ticket);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
        setCurrentTicket(null);
    };

    const handleStatusChange = async (status) => {
        // Lógica para alterar o status do ticket
        console.log(`Alterar status do ticket ${currentTicket.id} para ${status}`);

        try {
            const { data } = await api.put(`/tickets-admin/${currentTicket.id}`, { status });
            console.log("data", data);
        } catch (err) {
            toastError(err);
        }
        handleMenuClose();
    };

    const handleDeleteTicket = () => {
        // Lógica para deletar o ticket
        console.log(`Deletar ticket ${currentTicket.id}`);
        handleMenuClose();
    };

    return (
        <>
            {user.id === 1 ? (
                <MainContainer>
                    <SearchTickets handleSearchResults={(results) => setParams(results)} handlePauseSocket={() => setPauseSocket(!pauseSocket)} pauseSocket={pauseSocket} />
                    <Paper
                        className={classes.mainPaper}
                        variant="outlined"
                        onScroll={handleScroll}
                    >
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell padding="checkbox" />
                                    <TableCell>{i18n.t("Contato")}</TableCell>
                                    <TableCell align="center">
                                        {i18n.t("Status")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("Ultima Mensagem")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("Messages")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("Fila")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("Usuário")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("Data de Criação")}
                                    </TableCell>

                                    <TableCell align="center">
                                        {i18n.t("Última Atualização")}
                                    </TableCell>
                                    <TableCell align="center">
                                        {i18n.t("tickets.table.actions")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <>
                                    {tickets.map((ticket) => (
                                        <TableRow key={ticket.id}>
                                            <TableCell style={{ paddingRight: 0 }}>
                                                <Link to={`/tickets/${ticket.uuid}`} target="_blank">
                                                    <Avatar src={ticket.contact.profilePicUrl} />
                                                </Link>
                                            </TableCell>
                                            <TableCell>{ticket.contact.name} <br /> 
                                            <small>{ticket.contact.number}</small>
                                            <br/>
                                            <small>{ticket.id}</small>
                                            <br/>
                                     
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    aria-controls="simple-menu"
                                                    aria-haspopup="true"
                                                    onClick={(event) => handleMenuClick(event, ticket)}
                                                >
                                                    {ticket.status}
                                                </Button>
                                                <Menu
                                                    id="simple-menu"
                                                    anchorEl={anchorEl}
                                                    keepMounted
                                                    open={Boolean(anchorEl)}
                                                    onClose={handleMenuClose}
                                                >
                                                    <MenuItem onClick={() => handleStatusChange("open")}>Open - Atendendo</MenuItem>
                                                    <MenuItem onClick={() => handleStatusChange("pending")}>Pending - Aguardando</MenuItem>
                                                    <MenuItem onClick={() => handleStatusChange("closed")}>Closed - Resolvido</MenuItem>
                                                    <MenuItem onClick={() => handleStatusChange("apiOpen")}>API Open</MenuItem>
                                                    <MenuItem onClick={() => handleStatusChange("apiPending")}>API Pending</MenuItem>
                                                    <MenuItem onClick={() => handleStatusChange("apiClosed")}>API Closed</MenuItem>
                                                </Menu>
                                            </TableCell>
                                            <TableCell align="center">{ticket.lastMessage}</TableCell>
                                            <TableCell align="center"><Button disabled={!ticket?.messages?.length} onClick={() => handleMessaOpen(ticket)}>{ticket?.messages?.length || 0}</Button> </TableCell>
                                            <TableCell align="center">{ticket.queue?.name}</TableCell>
                                            <TableCell align="center">{ticket.user?.name}</TableCell>
                                            <TableCell align="center">
                                                <DateDisplay date={ticket.createdAt} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <DateDisplay date={ticket.updatedAt} />
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button variant="contained" size="small" color="primary" onClick={() => handleOpen(ticket)}>
                                                    <CodeIcon />
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {loading && <TableRowSkeleton avatar columns={3} />}
                                </>
                            </TableBody>
                        </Table>
                    </Paper>
                    <Modal
                        open={open}
                        onClose={handleClose}
                        className={classes.modal}
                    >
                        <div className={classes.paper}>
                            <h2>Detalhes do Ticket</h2>
                            {selectedTicket && (
                                <ReactJson src={selectedTicket} theme="monokai" />
                            )}
                            <Button variant="contained" color="secondary" onClick={handleClose}>
                                Fechar
                            </Button>
                        </div>
                    </Modal>
                    <Modal
                        open={openMessages}
                        onClose={handleCloseMessages}
                        className={classes.modal}
                    >
                        <div className={classes.paper}>
                            <h2>Mensagens do Ticket</h2>
                            {messages.map((message) => (    
                                <ReactJson src={message} theme="monokai" />
                            ))}
                            <Button variant="contained" color="secondary" onClick={handleCloseMessages}>
                                Fechar
                            </Button>
                        </div>
                    </Modal>
                </MainContainer>

            ) : (
                <h3>Você não tem permissão para acessar esta página</h3>

            )}
        </>

    );
};

export default TicketsAdmin;
