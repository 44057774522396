import React, { useState, useEffect, useReducer, useContext } from "react";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import SearchIcon from "@material-ui/icons/Search";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../context/Auth/AuthContext";
import { Button, Checkbox, Dialog, FormControlLabel } from "@material-ui/core";
import openSocket from "../../services/socket-io";
import moment from "moment";
import MessageLogModal from "../../components/MessageLogModal";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const reducer = (state, action) => {
    if (action.type === "LOAD_DATAS") {
        const messagesBruteLogs = action.payload;
        const newUsers = [];

        messagesBruteLogs.forEach((messagesBruteLog) => {
            const userIndex = state.findIndex((u) => u.id === messagesBruteLog.id);
            if (userIndex !== -1) {
                state[userIndex] = messagesBruteLog;
            } else {
                newUsers.push(messagesBruteLog);
            }
        });

        return [...state, ...newUsers];
    }

    if (action.type === "UPDATE_DATAS") {
        const messagesBruteLog = action.payload;
        const userIndex = state.findIndex((u) => u.id === messagesBruteLog.id);

        if (userIndex !== -1) {
            state[userIndex] = messagesBruteLog;
            return [...state];
        } else {
            return [messagesBruteLog, ...state];
        }
    }

    if (action.type === "DELETE_DATA") {
        const messagesBruteLogId = action.payload;

        const userIndex = state.findIndex((u) => u.id === messagesBruteLogId);
        if (userIndex !== -1) {
            state.splice(userIndex, 1);
        }
        return [...state];
    }

    if (action.type === "RESET") {
        return [];
    }
};

const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    unknownType: {
        backgroundColor: 'orange', // Defina a cor de fundo desejada aqui
    },
    warnType: {
        backgroundColor: 'yellow', // Defina a cor de fundo desejada aqui
    },


}));

const MessageLogs = () => {
    const classes = useStyles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [selectedMessageLog, setSelectedMessageLog] = useState(null);
    const [deletingUser, setDeletingUser] = useState(null);
    const [userModalOpen, setMessageLogModalOpen] = useState(false);
    const [UserAuthorizationModalOpen, setUserAuthorizationModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [searchParamWitchError, setSearchParamWitchError] = useState('');
    const [messagesBruteLogs, dispatch] = useReducer(reducer, []);
    const { user } = useContext(AuthContext);
    const [valueUnknownType, setValueUnknownType] = useState();
    const [valueWarnType, setValueWarnType] = useState();
    const [logInfoModalOpen, setLogInfoModalOpen] = useState(false);
    const [selectedLogInfo, setSelectedLogInfo] = useState("");

    const location = useLocation();

    const intoPage = location?.pathname.includes('/messageLogs')


    useEffect(() => {
        if (valueWarnType) {
            setSearchParamWitchError('wanType')
        } else {
            setSearchParamWitchError('')
        }
    }, [valueWarnType]);

    useEffect(() => {
        if (valueUnknownType) {
            setSearchParamWitchError('unknownType')
        } else {
            setSearchParamWitchError('')
        }
    }, [valueUnknownType]);

    useEffect(() => {
        dispatch({ type: "RESET" });
        setPageNumber(1);
    }, [searchParam, searchParamWitchError]);

    useEffect(() => {
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get("/messageLogs/", {
                        params: { searchParam, pageNumber, searchParamWitchError },
                    });
                    dispatch({ type: "LOAD_DATAS", payload: data.messageLogs });
                    setHasMore(data.hasMore);
                    setLoading(false);
                } catch (err) {
                    toastError(err);
                }
            };
            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, pageNumber, searchParamWitchError]);

    useEffect(() => {

        if(!intoPage){return}
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
        socket.on(`messageLog`, (data) => {
            if (data.action === "update" || data.action === "create") {
                dispatch({ type: "UPDATE_DATAS", payload: data.messageLog });
                if (data.message) {
                    toast.info(data.message)
                }
            }

            if (data.action === "delete") {
                dispatch({ type: "DELETE_DATA", payload: +data.messagesLogId });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const handleOpenMessageLogModal = () => {
        setSelectedMessageLog(null);
        setMessageLogModalOpen(true);
    };

    const handleReprocessMessage = async (id) => {
        //console.log("Reprocessar", messagesBruteLog)

        try {
            if (id) {
                //console.log("Aqui")
                await api.post(`/reprocessMessage/${id}`);
            }

            //toast.success(i18n.t("Mensagem enviada para processamento"));
        } catch (err) {
            toastError(err);
        }
    };

    const handleCloseMessageLogModal = () => {
        setSelectedMessageLog(null);
        setMessageLogModalOpen(false);
    };



    const handleCloseUserAuthorizationModal = () => {
        setUserAuthorizationModalOpen(false);
    };

    const handleSearch = (event) => {
        setSearchParam(event.target.value.toLowerCase());
    };

    const handleEditUser = (messagesBruteLog) => {
        setSelectedMessageLog(messagesBruteLog);
        setMessageLogModalOpen(true);
    };

    const handleDeleteLogs = async () => {
        try {
            //console.log("Quando autorzar deve delatar")
            await api.delete(`/messageLogs`);
            api.post(`/restartpm2`);
            toast.success(i18n.t("messagesBruteLogs.toasts.deleted"));
        } catch (err) {
            toastError(err);
        }
        setDeletingUser(null);
        setSearchParam(0);

        setTimeout(() => {
            setSearchParam("");
            setPageNumber(1);
        }, 500);
    };

    const loadMore = () => {
        setPageNumber((prevState) => prevState + 1);
    };

    const handleScroll = (e) => {
        if (!hasMore || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore();
        }
    };

    const handleOpenLogInfoModal = (logInfo) => {
        setSelectedLogInfo(logInfo);
        setLogInfoModalOpen(true);
    };

    return (<>
    {console.log(user)}
        {user.id === 1 ?

            <MainContainer>
                <ConfirmationModal
                    title="Deletar todos os logs da aplicação"
                    open={confirmModalOpen}
                    onClose={setConfirmModalOpen}
                    onConfirm={() => handleDeleteLogs()}
                >
                    {i18n.t("Este Processo é irreversível deseja continuar mesmo assim ?")}
                </ConfirmationModal>
                <MessageLogModal
                    open={userModalOpen}
                    onClose={handleCloseMessageLogModal}
                    aria-labelledby="form-dialog-title"
                    messagesLog={selectedMessageLog}
                />



                <MainHeader>
                    <Title>{i18n.t("Logs brutos de mensagens")}</Title>
                    <MainHeaderButtonsWrapper>



                        <Button
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                                setConfirmModalOpen(true);
                                //setDeletingUser(user);
                            }}
                        >
                            Deletar todos os logs da aplicação
                        </Button>
                        <TextField
                            placeholder={i18n.t("contacts.searchPlaceholder")}
                            type="search"
                            value={searchParam}
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: "gray" }} />
                                    </InputAdornment>
                                ),
                            }}
                        />

                        <FormControlLabel
                            control={<Checkbox name={"unknownType"} title={"tipo desconhecido"} color="default" />}
                            label={"tipo desconhecido"}
                            labelPlacement={"end"}
                            onChange={(e) => {
                                setValueUnknownType(e.target.checked ? 1 : 0)
                            }}
                            value={valueUnknownType}
                            checked={valueUnknownType}
                        />
                        <FormControlLabel
                            control={<Checkbox name={"warnType"} title={"tipo alerta"} color="default" />}
                            label={"tipo alerta"}
                            labelPlacement={"end"}
                            onChange={(e) => {
                                setValueWarnType(e.target.checked ? 1 : 0)
                            }}
                            value={valueWarnType}
                            checked={valueWarnType}
                        />


                    </MainHeaderButtonsWrapper>
                </MainHeader>
                <Paper
                    className={classes.mainPaper}
                    variant="outlined"
                    onScroll={handleScroll}
                >
                    <Table size="small">
                        <TableHead>
                            <TableRow>
{/*                                 <TableCell className="color-quaternary" >
                                    {i18n.t("Ações")}
                                </TableCell> */}
                                <   TableCell size="medium" className="color-quaternary" >{i18n.t("data")}</TableCell>
                                <TableCell className="color-quaternary" >{i18n.t("T.gasto")}</TableCell>
                                <TableCell className="color-quaternary" >


                                    {i18n.t("ticketId")}</TableCell>
                                <TableCell className="color-quaternary" >
                                    {i18n.t("remoteJid")}
                                </TableCell>
                                <TableCell className="color-quaternary" >
                                    {i18n.t("logInfo")}
                                </TableCell>
                                <TableCell className="color-quaternary" >
                                    whatsappId
                                </TableCell>
                                <TableCell className="color-quaternary" >
                                    Tipo
                                </TableCell>
                                <TableCell className="color-quaternary" >
                                    conteúdo
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <>
                                {messagesBruteLogs.map((messagesBruteLog) => (
                                    <TableRow key={messagesBruteLog.id} className={messagesBruteLog?.ticketId ?


                                        (messagesBruteLog?.ticketId < 0 ? classes.warnType : null)
                                        : classes.unknownType} >
                                        <TableCell size="medium" >{
                                            moment(messagesBruteLog?.createdAt).format("DD/MM HH:mm:ss")
                                        }
                                        </TableCell>
                                        <TableCell className="color-quaternary" >{messagesBruteLog?.timeInProcess}</TableCell>
                                        <TableCell className="color-quaternary" >
                                            {messagesBruteLog?.ticketId > 0 ?
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    href={`./tickets/${messagesBruteLog?.ticketUuid}`}
                                                    target="_blank"
                                                //onClick={()=>history.go(`./tickets/${messagesBruteLog?.ticketId}`)}
                                                >
                                                    {messagesBruteLog?.ticketId}
                                                </Button>
                                                :
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() => handleReprocessMessage(messagesBruteLog.id)}
                                                >
                                                    {messagesBruteLog?.ticketUuid}
                                                </Button>

                                            }
                                        </TableCell>

                                        <TableCell className="color-quaternary" ><div>{messagesBruteLog?.remoteJid}</div><div>{messagesBruteLog?.messageId}</div></TableCell>
                                        <TableCell className="color-quaternary">
                                            <div style={{display:"flex", flexDirection:"row", gap:"10px"}}>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleOpenLogInfoModal(messagesBruteLog?.logInfo)}
                                            >
                                               Log
                                            </Button>
                                            <Button
                                                color="primary"
                                                variant="contained"
                                                size="small"
                                                onClick={() => handleEditUser(messagesBruteLog)}
                                            >
                                            {i18n.t("Reprocessar")}
                                            </Button>

                                            </div>

                                        </TableCell>
                                        <TableCell className="color-quaternary" >{messagesBruteLog?.whatsappId}</TableCell>
                                        <TableCell className="color-quaternary" >{messagesBruteLog?.messageType}</TableCell>
                                        <TableCell className="color-quaternary" >{messagesBruteLog?.body}</TableCell>
                                    </TableRow>
                                ))}
                                {loading && <TableRowSkeleton columns={4} />}
                            </>
                        </TableBody>
                    </Table>
                </Paper>
            </MainContainer>

            : null}

        <Dialog
            open={logInfoModalOpen}
            fullScreen
            onClose={() => setLogInfoModalOpen(false)}
            aria-labelledby="log-info-dialog-title"
        >
            <div>
                <h2 id="log-info-dialog-title">Log Info</h2>
                <pre>{selectedLogInfo}</pre>
            </div>
        </Dialog>

    </>

    );
};

export default MessageLogs;