export const minutes = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
export const hours = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24']
export const googleMapsInfo = `
    <h4>Passo a Passo para Cadastrar a API Key</h4>
    <p>Para utilizar a API do Google Maps, você precisa obter uma chave de API (API Key). Siga os passos abaixo para cadastrar a sua chave:</p>
    <ol>
        <li>
            <strong>Crie um projeto no Google Cloud Platform:</strong>
            <p>Acesse o <a href="https://console.cloud.google.com/" target="_blank">Google Cloud Console</a> e crie um novo projeto ou selecione um projeto existente.</p>
        </li>
        <li>
            <strong>Ative a API JavaScript do Google Maps:</strong>
            <p>No painel do projeto, vá para "APIs e Serviços" e clique em "Biblioteca". Procure por "Maps JavaScript API" e clique em "Ativar".</p>
        </li>
                <li>
            <strong>Ative a API Places do Google Maps:</strong>
            <p>No painel do projeto, vá para "APIs e Serviços" e clique em "Biblioteca". Procure por "Places API" e clique em "Ativar".</p>
        </li>
        <li>
            <strong>Obtenha a API Key:</strong>
            <p>Após ativar a API, vá para "APIs e Serviços" e clique em "Credenciais". Clique em "Criar credenciais" e selecione "Chave de API". A sua chave de API será gerada.</p>
        </li>
        <li>
            <strong>Restrinja a API Key (opcional):</strong>
            <p>Para maior segurança, você pode restringir o uso da sua chave de API. Clique na chave gerada e configure as restrições de uso, como restrições de IP ou de referenciador HTTP.</p>
        </li>
    </ol>

    <h2>Informações sobre o Nível Gratuito</h2>
    <p>O Google Cloud oferece um nível gratuito que inclui um crédito mensal de $200 para o uso das APIs do Google Maps. Isso é suficiente para a maioria dos pequenos projetos e testes. Aqui estão alguns detalhes importantes:</p>
    <ul>
        <li>O crédito de $200 é renovado mensalmente.</li>
        <li>Você pode usar várias APIs do Google Maps dentro desse limite, incluindo a Maps JavaScript API, Geocoding API, e Places API.</li>
        <li>Se o uso exceder o crédito gratuito, você será cobrado pelas solicitações adicionais. É importante monitorar o uso para evitar cobranças inesperadas.</li>
    </ul>
<h2>Importante Salientar</h2>
    <p>É importante salientar que só será contabilizado requisição no momento do cadastro ou edição de uma localização.Quando é enviado pela plataforma não conta como requisição</p>
<h2>Links Úteis</h2>
    <p>Para mais informações, consulte os seguintes links:</p>
    <ul>
        <li><a href="https://cloud.google.com/maps-platform/pricing/" target="_blank">Preços do Google Maps Platform</a></li>
        <li><a href="https://developers.google.com/maps/gmp-get-started" target="_blank">Guia de Introdução ao Google Maps Platform</a></li>
        <li><a href="https://cloud.google.com/free" target="_blank">Nível Gratuito do Google Cloud</a></li>
    </ul>
`;