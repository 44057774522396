import { useState, useEffect } from "react";
import api from "../../services/api";


const useTickets = ({
        searchParam,
        pageNumber,
        tags,
        tab,
        status,
        date,
        updatedAt,
        showAll,
        queueIds,
        withUnreadMessages,
        searchContactId,
        searchUserId,
        queueId,
        searchInitialDate,
        searchFinalDate,
        selectedUserIds
}) => {
        const [loading, setLoading] = useState(true);
        const [hasMore, setHasMore] = useState(false);
        const [tickets, setTickets] = useState([]);
        const [count, setCount] = useState(0);
 

        useEffect(() => {
                setLoading(true);
                const delayDebounceFn = setTimeout(() => {
                        const fetchTickets = async () => {
                                try {
                                        const { data } = await api.get("/tickets", {

                                                params: {
                                                        searchParam,
                                                        tags,
                                                        tab,
                                                        pageNumber,
                                                        status,
                                                        date,
                                                        updatedAt,
                                                        showAll,
                                                        queueIds,
                                                        withUnreadMessages,
                                                        searchContactId,
                                                        searchUserId,
                                                        queueId,
                                                        searchInitialDate,
                                                        searchFinalDate,
                                                        selectedUserIds
                                                },
                                        });

                                        setTickets(data.tickets);
                                        setHasMore(data.hasMore);
                                        setCount(data.count);
                                        setLoading(false);
                                } catch (err) {
                                        setLoading(false);

                                        try {
                                                setTimeout(async () => {
                                                        const { data } = await api.get("/tickets", {

                                                                params: {
                                                                        searchParam,
                                                                        tags,
                                                                        tab,
                                                                        pageNumber,
                                                                        status,
                                                                        date,
                                                                        updatedAt,
                                                                        showAll,
                                                                        queueIds,
                                                                        withUnreadMessages,
                                                                        searchContactId,
                                                                        searchUserId,
                                                                        queueId,
                                                                        searchInitialDate,
                                                                        searchFinalDate,
                                                                        selectedUserIds
                                                                },
                                                        });
                
                                                        setTickets(data.tickets);
                                                        setHasMore(data.hasMore);
                                                        setCount(data.count);
                                                        setLoading(false); 
                                                }, 5000);
                                                
                                        } catch (error) {
                                                console.error("Falha na requisição de tickets",err)      
                                        }
          
                                        
                                }
                        };
                        fetchTickets();
                }, 500);
                return () => clearTimeout(delayDebounceFn);
        }, [
                searchParam,
                pageNumber,
                status,
                tags,
                date,
                updatedAt,
                showAll,
                queueIds,
                withUnreadMessages,
                queueId,
                selectedUserIds
        ]);

        return { tickets, loading, hasMore, count };
};

export default useTickets;