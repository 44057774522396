import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import LoggedInLayout from "../layout";
import Dashboard from "../pages/Dashboard/";
import Tickets from "../pages/Tickets/";
import Login from "../pages/Login/";
import Connections from "../pages/Connections/";
import Settings from "../pages/Settings/";
import Users from "../pages/Users";
import Contacts from "../pages/Contacts/";
import QuickAnswers from "../pages/QuickAnswers/";
import Schedules from "../pages/Schedules/";
import Queues from "../pages/Queues/";
import Tags from "../pages/Tags/";
import MessagesAPI from "../pages/MessagesAPI/";
import { AuthProvider } from "../context/Auth/AuthContext";
import { WhatsAppsProvider } from "../context/WhatsApp/WhatsAppsContext";
import Route from "./Route";
import Reports from "../pages/Reports";
import Bots from "../pages/Bots";
import Classification from "../pages/Classification";
import MessageLogs from "../pages/MessageLogs";
import NpsSurvey from "../pages/NpsSurvey";
import NpsManager from "../pages/NpsSurvey/manager";
import Expedient from "../pages/Expedient";
import ExpedientManager from "../pages/Expedient/manager";
import Monitor from "../pages/Monitor";
import Version from "../pages/Version";
import Api from "../pages/Api";
import ApiToken from "../pages/ApiToken";
import Integration from "../pages/Integration";
import LocationOnMaps from "../components/LocationOnMaps";
import Location from "../pages/Location";
import TicketsAdmin from "../pages/TicketAdmin";


const Routes = () => {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Switch>
          <Route exact path="/login" component={Login} />
          {/* <Route exact path="/signup" component={Signup} /> */}
          <WhatsAppsProvider>
            <LoggedInLayout>
              <Route exact path="/dashboard" component={Dashboard} isPrivate />
              <Route
                exact
                path="/tickets/:ticketId?"
                component={Tickets}
                isPrivate
              />
              <Route
                exact
                path="/connections"
                component={Connections}
                isPrivate
              />
              <Route exact path="/contacts" component={Contacts} isPrivate />
              <Route exact path="/users" component={Users} isPrivate />
              <Route
                exact
                path="/quickAnswers"
                component={QuickAnswers}
                isPrivate
              />
              <Route exact path="/schedules" component={Schedules} isPrivate />
              <Route exact path="/reports" component={Reports} isPrivate />
              <Route exact path="/tags" component={Tags} isPrivate />
              <Route exact path="/messages-api" component={MessagesAPI} isPrivate />
              <Route exact path="/settings" component={Settings} isPrivate />
              <Route exact path="/queues" component={Queues} isPrivate />
              <Route exact path="/Bots" component={Bots} isPrivate />
              <Route exact path="/classification" component={Classification} isPrivate />
              <Route exact path="/messageLogs" component={MessageLogs} isPrivate />
              <Route exact path="/npsSurvey" component={NpsSurvey} isPrivate />
              <Route exact path="/npsManager/:npsSurveyId?" component={NpsManager} isPrivate />
              <Route exact path="/expedient" component={Expedient} isPrivate />
              <Route exact path="/expedientManager/:expedientId?" component={ExpedientManager} isPrivate />
              <Route exact path="/monitor" component={Monitor} isPrivate />
              <Route exact path="/version" component={Version} isPrivate />
              <Route exact path="/v" component={Version} isPrivate />
              <Route exact path="/api" component={Api} isPrivate />
              <Route exact path="/api-token" component={ApiToken} isPrivate />
              <Route exact path="/integration" component={Integration} isPrivate />
              <Route exact path="/maps" component={LocationOnMaps} isPrivate />
              <Route exact path="/locations/:id?" component={Location} isPrivate />
              <Route exact path="/tickets-admin" component={TicketsAdmin} isPrivate />
            </LoggedInLayout>
          </WhatsAppsProvider>
        </Switch>
        <ToastContainer autoClose={3000} />
      </AuthProvider>
    </BrowserRouter>
  );
};

export default Routes;
