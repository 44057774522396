import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import { useHistory } from "react-router-dom";
import axios from "axios";
export default function MenuApi(props) {
    const history = useHistory();
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };


  return (
    <>
      <Button
        variant="contained"
        color="primary"
        aria-haspopup="true"
        onClick={handleClick}
      >
        {i18n.t("common.menu")}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
      >
        <MenuItem onClick={()=> history.push(`/api-token`)}>
          {i18n.t("api.menu.token")}
        </MenuItem>
      </Menu>
    </>
  );
}