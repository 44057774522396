import React from 'react';
import { Card, CardContent, Typography, Avatar } from '@mui/material';
import MarkdownWrapper from '../MarkdownWrapper';


const ContactCard = ({ contact }) => {
    return (
        <Card style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', margin:12, borderRadius:18 , border: '1px solid #e0e0e0'}}>
            <CardContent>
            <Typography variant="h6" component="div" style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar>{contact?.name?.charAt(0)}</Avatar> {/* Adiciona um avatar */}
                    <span style={{ marginLeft: '8px' }}>{contact?.name}</span>
                </Typography>
                <p style={{ margin: '0px', fontSize: '12px' }}>
                    
                    {contact?.waid?.map((waid, index) => (
                        <div key={index}><strong>Whatsapp:</strong><MarkdownWrapper>{waid}</MarkdownWrapper></div> 
                    ))}
                </p>
                <p style={{ margin: '0px', fontSize: '12px' }}>
                    
                    {contact?.VOICE?.filter(voice => !contact.waid.includes(voice)).map((voice, index) => (
                        <div key={index}><strong>Telefone:</strong>{voice}</div>
                    ))}
                </p>
            </CardContent>
        </Card>
    );
};
const parseVCard = (vCardString) => {
    let vcard = vCardString.replace(/item\d+\./g, '')
    try {
        const lines = vcard.split('\n');
        const contact = {
            name: '',
            waid: [],
            VOICE: []
        };

        lines.forEach(line => {
            if (line.startsWith('FN:')) {
                contact.name = line.substring(3).trim();
            } else if (line.startsWith('TEL;')) {
                const waidMatch = line.match(/waid=([\d]+):/);
                const voiceMatch = line.match(/:(.+)$/);
                if (waidMatch && !contact.waid.includes(waidMatch[1])) {
                    contact.waid.push(waidMatch[1]);
                }
                if (voiceMatch && !contact.VOICE.includes(voiceMatch[1].trim())) {
                    contact.VOICE.push(voiceMatch[1].trim());
                }
            }
        });

        return contact;
    } catch (error) {
        console.error('Erro ao parsear o vCard:', error);
        return null;
    }
};




export const RenderVcard = (vcard) => {
    console.log(vcard)
    try {
        const contact = parseVCard(vcard)
        console.log(contact)
        return (
            <ContactCard contact={contact} />
        )
    } catch (error) {
        return (
            <div>
                <h1>Erro ao renderizar o vcard</h1>
            </div>
        )
    }
}

export const RenderMultiVcard = (contacts) => {
    console.log(contacts)
    try {
        return (
            <div>
                {contacts.map((c, index) => {
                    console.log(c.vcard)
                    const contact = parseVCard(c.vcard)
                    console.log(contact)
                    return <ContactCard key={index} contact={contact} />
                })}
            </div>
        );
    } catch (error) {
        return (
            <div>
                <h1>Erro ao renderizar o vcard</h1>
            </div>
        )
    }
}
