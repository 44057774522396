import React, { useContext, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MoveToInboxIcon from "@material-ui/icons/MoveToInbox";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import NewTicketModal from "../NewTicketModal";
import TicketsList from "../TicketsList";
import TabPanel from "../TabPanel";
import { i18n } from "../../translate/i18n";
import { AuthContext } from "../../context/Auth/AuthContext";
import TicketsQueueSelect from "../TicketsQueueSelect";
import { Button, FormControl, Tooltip } from "@material-ui/core";
import { TagsFilter } from "../TagsFilter";
import { TextField } from "@material-ui/core";
import Autocomplete, { createFilterOptions } from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import api from "../../services/api";
import { toast } from "react-toastify";
import moment from "moment";
import UserSelectFilter from "../UserSelectFilter";
import FeedbackIcon from '@material-ui/icons/Feedback';

const filter = createFilterOptions({
  trim: true,
});

const useStyles = makeStyles((theme) => ({
  ticketsWrapper: {
    position: "relative",
    display: "flex",
    height: "100%",
    flexDirection: "column",
    overflow: "hidden",
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },

  tabsHeader: {
    flex: "none",
    backgroundColor: "#eee",
  },

  settingsIcon: {
    alignSelf: "center",
    marginLeft: "auto",
    padding: 8,
  },

  tab: {
    minWidth: 120,
    width: 120,
    borderRadius: 4,
    textAlign: 'center',
    padding: 0,
    flex: 1
  },

  ticketOptionsBox: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#fafafa",
    padding: theme.spacing(1),
  },

  searchInputWrapper: {
    flex: 1,
    background: "#fff",
    display: "block",
    borderRadius: 40,
    padding: 4,
    marginRight: theme.spacing(1),
  },

  searchIcon: {
    color: "white",
    marginLeft: 6,
    marginRight: 6,
    alignSelf: "center",
  },

  searchInput: {
    flex: 1,
    border: "none",
    borderRadius: 30,
  },
}));


const TicketsManager = () => {
  const classes = useStyles();
  const history = useHistory();
  const [searchButtonPress, setSearchButtonPress] = useState("");
  const [searchParam, setSearchParam] = useState("");
  const [searchContact, setSearchContact] = useState("");
  const [searchUserParam, setSearchUserParam] = useState("");
  const [tab, setTab] = useState("open");
  const [newTicketModalOpen, setNewTicketModalOpen] = useState(false);
  const searchInputRef = useRef();
  const { user } = useContext(AuthContext);
  const userQueueIds = user.queues.map((q) => q.id);
  const [selectedQueueIds, setSelectedQueueIds] = useState(userQueueIds || []);
  const [selectedTags, setSelectedTags] = useState([]);
  const [value, setValue] = useState("tickets");
  const [options, setOptions] = useState([]);
  const [userOptions, setUserOptions] = useState([]);
  const [selectedContact, setSelectedContact] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingUser, setLoadingUser] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [initialDate, setInitialDate] = useState(moment().add(-30, "days").format("YYYY-MM-DDTHH:mm"));
  const [finalDate, setFinalDate] = useState(moment().add(0, "days").format("YYYY-MM-DDTHH:mm"));
  const [selectedUserIds, setSelectedUserIds] = useState(JSON.parse(localStorage.getItem("selectedUserIds")) || []);
  const [userList, setUserList] = useState([]);
  const [isGroupLength, setIsGroupLength] = useState({ open: 0, pending: 0, closed: 0 });
  //const userList = JSON.parse(localStorage.getItem("usersList")) || [];

  useEffect(() => {
    if (searchUserParam.length < 3) {
      setLoadingUser(false);
      return;
    }
    setLoadingUser(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchUsers = async () => {
        try {
          const { data } = await api.get("/users/", {
            params: { searchParam: searchUserParam },
          });
          setUserOptions(data.users);
          setLoadingUser(false);
        } catch (err) {
          setLoadingUser(false);
          toast.error("Não foi possível carregar os usuários")
        }
      };

      fetchUsers();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchUserParam]);

  useEffect(() => {
    localStorage.setItem("selectedUserIds", JSON.stringify(selectedUserIds));
  }, [selectedUserIds]);


  useEffect(() => {
    if (searchContact.length < 3) {
      setLoading(false);
      return;
    }
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      const fetchContacts = async () => {
        try {
          const { data } = await api.get("contacts", {
            params: { searchParam: searchContact },
          });
          setOptions(data.contacts);
          setLoading(false);
        } catch (err) {
          setLoading(false);
          toast.error("Falha na busca de contatos")

        }
      };

      fetchContacts();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchContact]);



  useEffect(() => {
    if (tab === "search") {
      searchInputRef.current.focus();
    }
  }, [tab]);

  useEffect(() => {
    if (user.profile === 'admin') {
      const fetchUsers = async () => {
        const { data } = await api.get("/users/list");
        setUserList(data);
      };
      fetchUsers();
    }
  }, []);



  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  const handleUserChange = (newSelectedIds) => {
    setSelectedUserIds(newSelectedIds);

  };


  const handleSearch = (e) => {
    setSearchButtonPress(new Date().getTime())

  };



  const renderOption = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${i18n.t("newTicketModal.add")} ${option.name}`;
    }
  };





  const renderOptionLabel = (option) => {
    if (option.number) {
      return `${option.name} - ${option.number}`;
    } else {
      return `${option.name}`;
    }
  };

  const handleChangeTab = (e, newValue) => {
    setTab(newValue);
  };

  const handleCloseOrOpenTicket = (ticket) => {
    setNewTicketModalOpen(false);
    if (ticket !== undefined && ticket.uuid !== undefined) {
      history.push(`/tickets/${ticket.uuid}`);
    }
  }

  const handleSelectedTags = (selecteds) => {
    const tags = selecteds.map(t => t.id);
    setSearchButtonPress("")
    setSelectedTags(tags);
  }

  const handleSelectOption = (e, newValue) => {
    if (newValue?.number) {
      setSearchButtonPress("")
      setSelectedContact(newValue.id);
    } else {
      setSearchButtonPress("")
      setSelectedContact();
    }
  };

  return (
    <Paper elevation={0} variant="outlined" className={classes.ticketsWrapper}>
      <NewTicketModal
        modalOpen={newTicketModalOpen}
        onClose={(ticket) => {
          handleCloseOrOpenTicket(ticket);
        }}
      />
      <Paper elevation={0} square className={classes.tabsHeader}>
        <Tabs
          style={{ marginTop: -6 }}
          value={tab}
          onChange={handleChangeTab}
          variant="fullWidth"
          indicatorColor="primary"
          textColor="primary"
          aria-label="icon label tabs example"
        >
          <Tab
            style={{ marginTop: -6 }}
            value={"open"}
            icon={<MoveToInboxIcon />}
            label={i18n.t("tickets.tabs.open.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            style={{ marginTop: -6 }}
            value={"closed"}
            icon={<CheckBoxIcon />}
            label={i18n.t("tickets.tabs.closed.title")}
            classes={{ root: classes.tab }}
          />
          <Tab
            style={{ marginTop: -6 }}
            value={"search"}
            icon={<SearchIcon />}
            label={i18n.t("tickets.tabs.search.title")}
            classes={{ root: classes.tab }}
          />
        </Tabs>
      </Paper>
      <Paper style={{ margin: -6 }} square elevation={0} className={classes.ticketOptionsBox}>
        {tab === "search" ? (
          <div className={classes.searchInputWrapper}>
            <FormControl fullWidth margin="dense" variant="outlined">
              <TextField
                variant="outlined"
                size="small"
                type="search"
                placeholder="Buscar Textos"
                onChange={e => {
                  setSearchParam(e.target.value.toLowerCase())
                  setSearchButtonPress("")
                }}
                inputRef={searchInputRef}
              />
            </FormControl>
            <TagsFilter onFiltered={handleSelectedTags} />
            <FormControl fullWidth margin="dense" variant="outlined">
              <Autocomplete
                size="small"
                fullWidth
                options={options}
                loading={loading}
                clearOnBlur
                autoHighlight
                freeSolo
                clearOnEscape
                getOptionLabel={renderOptionLabel}
                renderOption={renderOption}
                onChange={(e, newValue) => handleSelectOption(e, newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={i18n.t("newTicketModal.fieldLabel")}
                    variant="outlined"
                    autoFocus
                    onChange={(e) => setSearchContact(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>
            <FormControl fullWidth margin="dense" variant="outlined">
              <Autocomplete
                size="small"
                getOptionLabel={option => `${option.name}`}
                onChange={(e, newValue) => {
                  setSelectedUser(newValue?.id || null);
                  setSearchButtonPress("")
                }}
                options={userOptions}
                //filterOptions={filterOptions}
                freeSolo
                autoHighlight
                noOptionsText={i18n.t("transferTicketModal.noOptions")}
                loading={loadingUser}
                renderInput={params => (
                  <TextField
                    {...params}
                    label={i18n.t("transferTicketModal.fieldLabel")}
                    variant="outlined"
                    autoFocus
                    onChange={e => setSearchUserParam(e.target.value)}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loadingUser ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />
            </FormControl>

            <FormControl fullWidth margin="dense" variant="outlined">
              <div style={{ display: "flex" }}>
                <TextField
                  size="small"
                  style={{ marginRight: 6 }}
                  label={i18n.t("reports.initialDate")}
                  type="datetime-local"
                  name="initialDate"
                  variant="outlined"
                  fullWidth
                  value={moment(initialDate).format("YYYY-MM-DDTHH:mm")}
                  onChange={e => {
                    setInitialDate(e.target.value)
                    setSearchButtonPress("")
                  }

                  }
                />
                <TextField
                  size="small"
                  label={i18n.t("reports.finalDate")}
                  type="datetime-local"
                  name="finalDate"
                  variant="outlined"
                  fullWidth
                  value={moment(finalDate).format("YYYY-MM-DDTHH:mm")}
                  onChange={e => {
                    setFinalDate(e.target.value)
                    setSearchButtonPress("")
                  }
                  }
                />
              </div>


            </FormControl>


            <FormControl fullWidth margin="dense" variant="outlined">
              <Button
                endIcon={<SearchIcon className={classes.searchIcon} />}
                variant="contained"
                color="primary"
                onClick={handleSearch}
              >Buscar</Button>
            </FormControl>
          </div>

        ) : (
          <>
            <Button
              style={{ marginLeft: 6, marginBottom: 4 }}
              variant="contained"
              color="primary"
              size="medium"
              id="newTicketButton"
              onClick={() => setNewTicketModalOpen(true)}
            >
              {i18n.t("ticketsManager.buttons.newTicket")}
            </Button>

            {user?.profile === "admin" ?
              <UserSelectFilter
                newUserList={setUserList}
                userList={userList}
                selectedUserIds={selectedUserIds}
                onChange={handleUserChange} />
              : null}
          </>
        )}
        {tab !== "search" ?
          <TicketsQueueSelect
            style={{ marginLeft: 6 }}
            selectedQueueIds={selectedQueueIds}
            userQueues={user?.queues}
            onChange={(values) => setSelectedQueueIds(values)}
          />
          : null}
      </Paper>
      {tab !== "search" ?
        <Paper style={{ margin: 0, padding: 0 }} elevation={0} square className={classes.tabsHeader}>
          <div style={{ display: 'flex', margin: 0, padding: 0 }}>
            <div
              style={{ color: `${value === 'tickets' ? '#337ab7' : '#000'}`, border: `${value === 'tickets' ? '1px solid #337ab7' : 'none'}`, cursor: `${value !== 'tickets' ? 'pointer' : 'default'}` }}
              className={classes.tab}
              onClick={() => handleChangeTabs(null, "tickets")}
            >
              Tickets
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: `${value === 'grupos' ? '#337ab7' : '#000'}`, border: `${value === 'grupos' ? '1px solid #337ab7' : 'none'}`, cursor: `${value !== 'grupos' ? 'pointer' : 'default'}` }}
              className={classes.tab}
              onClick={() => handleChangeTabs(null, "grupos")}
            >
              <span>Grupos</span>

              {isGroupLength?.open ?
                <Tooltip title="Tickets de grupo em atendimento">
                  <FeedbackIcon style={{ marginLeft: 4, fontSize: 20, color: "#2e7d32" }} />
                </Tooltip>
                : null}

              {isGroupLength?.pending ?
                <Tooltip title="Tickets de grupo aguradando atendimento">
                  <FeedbackIcon style={{ marginLeft: 4, fontSize: 20, color: "#ed6c02" }} />
                </Tooltip>
                : null}
            </div>
          </div>
        </Paper>
        : null}

      {tab !== "search" ?
        <>
          <div style={{ display: `${value === "tickets" ? "flex" : "none"}` }} value={value} name="tickets" className={classes.ticketsWrapper}>

            {tab === "closed" ?
              <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
                <TicketsList
                  status="closed"
                  showAll={true}
                  selectedQueueIds={selectedQueueIds}
                />
              </TabPanel>
              : <>
                <TicketsList
                  status="open"
                  isGroup={false}
                  selectedQueueIds={selectedQueueIds}
                  selectedUserIds={selectedUserIds}
                />
                <TicketsList
                  status="pending"
                  isGroup={false}
                  selectedQueueIds={selectedQueueIds}
                  selectedUserIds={selectedUserIds}
                />

              </>}

          </div>
          <div
            style={{ display: `${value === "grupos" ? "flex" : "none"}` }}
            value={value} name="grupos" className={classes.ticketsWrapper}>

            {tab === "closed" ?
              <TabPanel value={tab} name="closed" className={classes.ticketsWrapper}>
                <TicketsList
                  status="closed"
                  showAll={true}
                  isGroup={true}
                  selectedQueueIds={selectedQueueIds}

                />
              </TabPanel>
              : <>

                <TicketsList
                  status="open"
                  showAll={true}
                  isGroup={true}
                  selectedQueueIds={selectedQueueIds}
                  updateCount={(e) => setIsGroupLength({ ...isGroupLength, open: e })}

                />
                <TicketsList
                  status="pending"
                  showAll={true}
                  isGroup={true}
                  selectedQueueIds={selectedQueueIds}
                  updateCount={(e) => setIsGroupLength({ ...isGroupLength, pending: e })}
                />

              </>}
          </div>
        </>
        : null}
      <TabPanel value={tab} name="search" className={classes.ticketsWrapper}>


        {searchButtonPress !== "" ?
          <TicketsList
            searchParam={searchParam}
            tags={selectedTags}
            tab={tab}
            searchButtonPress={searchButtonPress}
            searchContactId={selectedContact}
            searchUserId={selectedUser}
            searchInitialDate={initialDate}
            searchFinalDate={finalDate}


          />
          : null
        }

      </TabPanel>
    </Paper>
  );
};

export default TicketsManager;
