import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";

const CopyToClipBoard = (props) => {
  const [state, setstate] = useState("Clique para copiar");
  const { text } = props;
  const handleCopy = () => {
    setstate("Copiado para area de transferência");
    navigator.clipboard.writeText(text);
  };

  return (
    <Tooltip
      style={{ cursor: "pointer" }}
      arrow
      placement="right"
      title={state}
    >
      <span onClick={handleCopy} id={text}>
        {text}
      </span>
    </Tooltip>
  );
};

export default CopyToClipBoard;
