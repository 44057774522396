import React, { useContext, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import ClassIcon from '@material-ui/icons/Class';
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ListSubheader from "@material-ui/core/ListSubheader";
import Divider from "@material-ui/core/Divider";
import { Badge, makeStyles, MenuItem, Menu } from "@material-ui/core";
import DashboardOutlinedIcon from "@material-ui/icons/DashboardOutlined";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import SettingsOutlinedIcon from "@material-ui/icons/SettingsOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import ContactPhoneOutlinedIcon from "@material-ui/icons/ContactPhoneOutlined";
import AccountTreeOutlinedIcon from "@material-ui/icons/AccountTreeOutlined";
import QuestionAnswerOutlinedIcon from "@material-ui/icons/QuestionAnswerOutlined";
import CodeRoundedIcon from "@material-ui/icons/CodeRounded";
import EventIcon from "@material-ui/icons/Event";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import ManageBotsOutlinedIcon from "@material-ui/icons/InsertCommentOutlined";
import { i18n } from "../translate/i18n";
import { WhatsAppsContext } from "../context/WhatsApp/WhatsAppsContext";
import { AuthContext } from "../context/Auth/AuthContext";
import { AssessmentSharp } from "@material-ui/icons";
import PersonalVideoIcon from '@material-ui/icons/PersonalVideo';
import ContactSupportIcon from '@material-ui/icons/ContactSupport';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import SettingsApplicationsIcon from '@material-ui/icons/SettingsApplications';
const useStyles = makeStyles((theme) => ({
  mainPaper: {
    overflowY: "scroll",
    ...theme.scrollbarStyles,
    justifyContent: "space-around",
  },
}));

function ListItemLink2(props) {
  const { icon, primary, className } = props;

  return (
    <li>
      <ListItem button className={className}>
        {icon ? (
          <ListItemIcon titled={primary} className="item-menu-principal">
            {icon}
          </ListItemIcon>
        ) : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

function ListItemLink(props) {
  const { icon, primary, to, className } = props;

  const renderLink = React.useMemo(
    () =>
      React.forwardRef((itemProps, ref) => (
        <RouterLink to={to} ref={ref} {...itemProps} />
      )),
    [to]
  );

  return (
    <li>
      <ListItem style={{ width: "300px" }} button component={renderLink} className={className}>
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText primary={primary} />
      </ListItem>
    </li>
  );
}

const MainListItems = (props) => {
  const { drawerClose } = props;
  const { whatsApps } = useContext(WhatsAppsContext);
  const { user } = useContext(AuthContext);
  const [connectionWarning, setConnectionWarning] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (whatsApps.length > 0) {
        const offlineWhats = whatsApps.filter((whats) => {
          return (
            whats.status === "qrcode" ||
            whats.status === "PAIRING" ||
            whats.status === "DISCONNECTED" ||
            whats.status === "TIMEOUT" ||
            whats.status === "OPENING"
          );
        });
        if (offlineWhats.length > 0) {
          setConnectionWarning(true);
        } else {
          setConnectionWarning(false);
        }
      }
    }, 2000);
    return () => clearTimeout(delayDebounceFn);
  }, [whatsApps]);

  return (
    <div onClick={drawerClose}>
      <ListItemLink
        to="/connections"
        primary={i18n.t("mainDrawer.listItems.connections")}
        icon={
          <Badge
            overlap="rectangular"
            badgeContent={connectionWarning ? "!" : 0}
            color="error"
          >
            <SyncAltIcon />
          </Badge>
        }
      />
      <ListItemLink
        to="/tickets"
        primary={i18n.t("mainDrawer.listItems.tickets")}
        icon={<WhatsAppIcon />}
      />

      <ListItemLink
        to="/contacts"
        primary={i18n.t("mainDrawer.listItems.contacts")}
        icon={<ContactPhoneOutlinedIcon />}
      />
      <ListItemLink
        to="/schedules"
        primary={i18n.t("mainDrawer.listItems.schedules")}
        icon={<EventIcon />}
      />
      {/*       <ListItemLink
        to="/tags"
        primary={i18n.t("mainDrawer.listItems.tags")}
        icon={<LocalOfferIcon />}
      /> */}
      {user.profile === "admin" ?
        <>
          <Divider />
          <ListSubheader inset>
            {i18n.t("mainDrawer.listItems.administration")}
          </ListSubheader>
          <ListItemLink
            to="/reports"
            primary="Relatórios"
            icon={<AssessmentSharp />}
          />
          <ListItemLink
            to="/dashboard"
            primary="Dashboard"
            icon={<DashboardOutlinedIcon />}
          />
          <div onClick={() => setOpen(true)}>
            <ListItemLink2
              primary={i18n.t("mainDrawer.listItems.administration")}
              icon={<SettingsOutlinedIcon />}
            />
          </div>
          <div>
            <Menu
              id="demo-positioned-menu"
              aria-labelledby="demo-positioned-button"
              //anchorEl={anchorEl}
              open={open}
              onClose={() => setOpen(false)}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/quickAnswers"
                  primary={i18n.t("mainDrawer.listItems.quickAnswers")}
                  icon={<QuestionAnswerOutlinedIcon />}
                />
              </MenuItem>

              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/users"
                  primary={i18n.t("mainDrawer.listItems.users")}
                  icon={<PeopleAltOutlinedIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/tags"
                  primary={i18n.t("mainDrawer.listItems.tags")}
                  icon={<LocalOfferIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/queues"
                  primary={i18n.t("mainDrawer.listItems.queues")}
                  icon={<AccountTreeOutlinedIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/bots"
                  primary={i18n.t("mainDrawer.listItems.bots")}
                  icon={<ManageBotsOutlinedIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/classification"
                  primary={i18n.t("mainDrawer.listItems.classification")}
                  icon={<ClassIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/locations"
                  primary={i18n.t("Localização")}
                  icon={<LocationOnIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/expedient"
                  primary={i18n.t("mainDrawer.listItems.expedient")}
                  icon={<AccessTimeIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/npsSurvey"
                  primary={i18n.t("mainDrawer.listItems.npsSurvey")}
                  icon={<ContactSupportIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/api"
                  primary={i18n.t("mainDrawer.listItems.messagesAPI")}
                  icon={<CodeRoundedIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/integration"
                  primary={i18n.t("Integrações")}
                  icon={<SettingsApplicationsIcon />}
                />
              </MenuItem>
              <MenuItem onClick={() => setOpen(false)}>
                <ListItemLink
                  to="/monitor"
                  primary={i18n.t("Monitor")}
                  icon={<PersonalVideoIcon />}
                />
              </MenuItem>


              {user.id === 1 ?
                  <MenuItem onClick={() => setOpen(false)}>
                    <ListItemLink
                      to="/settings"
                      primary={i18n.t("Super Admin")}
                      icon={<SettingsOutlinedIcon />}
                    />
                  </MenuItem>
             
                : null}
            </Menu>
          </div>
        </>
        : null}




    </div>
  );
};

export default MainListItems;
