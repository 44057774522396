import React, { useState, useEffect, useReducer } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import TableRowSkeleton from "../../components/TableRowSkeleton";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";
import ApiTokenModal from "../../components/ApiTokenModal";
import CopyToClipBoard from "../../components/CopyToClipBoard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
const reducer = (state, action) => {
  if (action.type === "LOAD_DATA") {
    const apiTokens = action.payload;
    const newQuickAnswers = [];

    apiTokens.forEach((apiToken) => {
      const apiTokenIndex = state.findIndex((q) => q.id === apiToken.id);
      if (apiTokenIndex !== -1) {
        state[apiTokenIndex] = apiToken;
      } else {
        newQuickAnswers.push(apiToken);
      }
    });

    return [...state, ...newQuickAnswers];
  }

  if (action.type === "UPDATE_FLOW") {
    const apiToken = action.payload;
    const apiTokenIndex = state.findIndex((q) => q.id === apiToken.id);

    if (apiTokenIndex !== -1) {
      state[apiTokenIndex] = apiToken;
      return [...state];
    } else {
      return [apiToken, ...state];
    }
  }

  if (action.type === "DELETE_FLOW") {
    const apiTokenId = action.payload;

    const apiTokenIndex = state.findIndex((q) => q.id === apiTokenId);
    if (apiTokenIndex !== -1) {
      state.splice(apiTokenIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overapiTokenY: "scroll",
    ...theme.scrollbarStyles,
  },
}));

const ApiToken = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [apiTokens, dispatch] = useReducer(reducer, []);
  const [deleting, setDeleting] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [apiId, setApiTokenId] = useState(null);
  const history = useHistory();
  const [tokenModalOpen, setTokenModalOpen] = useState(false);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  const fetch = async () => {
    try {
      const { data } = await api.get("/api-token/", {
        params: { searchParam, pageNumber },
      });
      dispatch({ type: "LOAD_DATA", payload: data.apiTokens });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {

      fetch();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [searchParam, pageNumber]);


  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenModal = () => {
    setTokenModalOpen(true);
    fetch();
  };

  const handleEdit = (apiTokenId) => {

    setApiTokenId(apiTokenId)
    setTokenModalOpen(true);
    fetch();

  };

  const handleDelete = async (apiTokenId) => {
    try {
      await api.delete(`/api-token/${apiTokenId}`);

      toast.success(i18n.t("api.toasts.deleted"));

    } catch (err) {
      toastError(err);
    }
    setDeleting(null);
    setSearchParam("");
    setPageNumber(1);
    setTimeout(() => {
      dispatch({ type: "RESET" });
      fetch();
    }, 1500);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deleting &&
          `${i18n.t("api.confirmationModal.deleteTitle")} ${deleting.name
          }?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDelete(deleting.id)}
      >
        {i18n.t("common.irreversible")}
      </ConfirmationModal>
      <ApiTokenModal
        open={tokenModalOpen}
        onClose={() => {
          setTokenModalOpen(false)
          setApiTokenId(null)
          fetch()
        }}
        apiId={apiId}
      //userId={user?.id}
      />
      <MainHeader>
        <Title>{i18n.t("api.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("common.search")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={() => history.push(`/api`)}>
            {i18n.t("common.goBack")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            {i18n.t("api.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper
        className={classes.mainPaper + " color-quaternary "}
        variant="outlined"
        onScroll={handleScroll}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                {i18n.t("api.table.typeToken")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("api.table.name")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("api.table.token")}
              </TableCell>

              <TableCell align="center">
                {i18n.t("api.table.ticketSetStatus")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("api.table.whatsapp")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("api.table.queue")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("api.table.user")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("api.table.signature")}
              </TableCell>

              <TableCell align="center">
                {i18n.t("api.table.webhookUrl")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("api.table.webhookToken")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("api.table.timeOutTicketReOpen")}
              </TableCell>
              <TableCell align="center">
                {i18n.t("common.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <>
              {apiTokens.map((apiToken) => (
                <TableRow key={apiToken.id}>
                  <TableCell align="center">
                    {i18n.t(`api.table.${apiToken.typeToken}`)}
                  </TableCell>
                  <TableCell align="center">
                    {apiToken.name}
                  </TableCell>
                  <TableCell align="center">
                    <CopyToClipBoard text={apiToken.configToken} />
                  </TableCell>

                  <TableCell align="center">
                    { }
                    {i18n.t(`api.status.${apiToken.ticketSetStatus}`)}
                  </TableCell>
                  <TableCell align="center">
                    {apiToken?.whatsapp?.name}
                  </TableCell>
                  <TableCell align="center">
                    {apiToken?.queue?.name}
                  </TableCell>
                  <TableCell align="center">
                    {apiToken?.user?.name}
                  </TableCell>
                  <TableCell align="center">
                    {apiToken?.signature}
                  </TableCell>

                  <TableCell align="center">
                    {apiToken?.webhookUrl}
                  </TableCell>
                  <TableCell align="center">
                    {apiToken?.webhookToken}
                  </TableCell>
                  <TableCell align="center">
                    {i18n.t(`api.timeOutTicketReOpen.${apiToken.timeOutTicketReOpen}`)}
                  </TableCell>
                  <TableCell align="center">
                    <IconButton
                      size="small"
                      onClick={() => handleEdit(apiToken.id)}
                    >
                      <Edit />
                    </IconButton>

                    <IconButton
                      size="small"
                      onClick={(e) => {
                        setConfirmModalOpen(true);
                        setDeleting(apiToken);
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
              {loading && <TableRowSkeleton columns={3} />}
            </>
          </TableBody>
        </Table>
      </Paper>
    </MainContainer>
  );
};

export default ApiToken;
