import React from "react";
import packageJson from '../../../package.json';
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";

const Version = () => {
	return (
		<div style={{ margin: 12 }}>
			<MainContainer>
				<MainHeader>
					<Title>Version: {packageJson.version}</Title>
				</MainHeader>
			</MainContainer>
		</div>
	)
}

export default Version;