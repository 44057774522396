import React, { useState, useEffect, useContext } from "react";
import { Formik, Form, Field } from "formik";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
  styled,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import MainHeader from "../MainHeader";
import Title from "../Title";
import CopyToClipBoard from "../CopyToClipBoard";
import { v4 as uuidv4 } from 'uuid';
import InfoBadge from "../InfoBadge";
import { googleMapsInfo } from "../../helpers/commonConsts";
import SecretDisplayed from "../SecretDisplayed";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
  },
  form: {
    width: "100%",
  },
  panel: {
    margin: 8,
  },
  token: {
    margin: "auto",
    width: "50%",
  },
  button: {
    width: "50%",
    margin: 2,
    textAlign: "center",
    fontSize: "20px",
    height: "40px",
    paddingTop: 5,
    borderRadius: 9,
  },
  buttonLarge: {
    width: "100%",
    marginTop: 3,
    textAlign: "center",
    fontSize: "20px",
    height: "40px",
    paddingTop: 5,
    borderRadius: 9,
  },
}));


const IntegrationModal = ({ open, onClose, apiId, reload }) => {
  const classes = useStyles();
  const initialState = {
    id: null,
    name: "",
    apiUrl: "",
    apiToken: "", // Gera um UUID para apiToken
    integrationType: "",
    message: ""
  };

  const [integration, setIntegration] = useState(initialState);



  useEffect(() => {
    const fetchData = async () => {
      try {
        if (apiId) {
          const { data } = await api.get(`/integration/${apiId}`);
  
            setIntegration({...data, apiToken: "SEU_TOKEN"});
          
        }
      } catch (err) {
        toastError(err);
      }
    };

    if (open) {
      fetchData();
    }
  }, [open, apiId]);

  const handleClose = () => {
    onClose();
    setIntegration(initialState);
  };

  const handleSubmitForm = async (values) => {
    const integrationData = {
      ...values,
    };


    try {
      if (apiId) {
        await api.put(`/integration/${apiId}`, integrationData);
      } else {
        await api.post(`/integration/`, integrationData);
      }
      toast.success(i18n.t("integrationModal.success"));
      if (typeof reload === "function") reload();
    } catch (err) {
      toastError(err);
    }

    handleClose();
  };
  const handleType = (e) => { 
    if(e.target.value === "receiveTransferTicketEchat"){
      setIntegration(
        {
          ...integration, integrationType: e.target.value, message: "Recebemos a transferência do seu ticket em nosso whatsapp, trataremos o assunto a partir de agora",apiToken: uuidv4(), name:""
        })
    }
    else
    if(e.target.value === "sendTransferTicketEchat"){
      setIntegration(
        {
          ...integration, integrationType: e.target.value, message: "Transferimos seu ticket para outro centro de atendimento",apiToken: "", name:""
        })
      }
      else
      if(e.target.value === "googleMapsApi"){
        setIntegration(
          {
            ...integration, integrationType: e.target.value, message: "",apiToken: "SUA_API_KEY",name:"Google Maps API"
          })
      }
}

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth scroll="paper">
        <div className={classes.panel}>
          <Formik
            initialValues={integration}
            enableReinitialize
            onSubmit={(values, actions) => {
              setTimeout(() => {
                handleSubmitForm(values);
                actions.setSubmitting(false);
              }, 400);
            }}
          >
            {({ isSubmitting, values }) => (
              <>
                <MainHeader>
                  <Title>{i18n.t("Configuração de Token de Integração")}</Title>
                </MainHeader>
                <Form className={classes.form}>
                  <DialogContent dividers>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6} md={4}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FormControl variant="outlined" margin="dense" fullWidth>
                            <InputLabel id="integration.modal.integrationType">
                              {i18n.t("integration.modal.integrationType.label")}
                            </InputLabel>
                            <Field
                              as={Select}
                              label={i18n.t("integration.modal.integrationType.label")}
                              name="integrationType"
                              labelId="integration.modal.integrationType.label"
                              required
                              value={integration?.integrationType}
                              onChange={handleType}
                            >
                              <MenuItem value="receiveTransferTicketEchat">
                                Receber Transferência externa Echat
                              </MenuItem>
                              <MenuItem value="sendTransferTicketEchat">
                                Enviar Transferência externa Echat
                              </MenuItem>
                              <MenuItem value="googleMapsApi">
                                Google Maps Localização
                              </MenuItem>
                            </Field>
                          </FormControl>
                          {integration.integrationType === "googleMapsApi" ? <>
                            <InfoBadge
                            infoText={"Ajuda sobre o recurso"}
                            modalTitle={"Api de integração do google maps"}
                            modalContent={<div dangerouslySetInnerHTML={{ __html: googleMapsInfo }} />}
                          />
                          </>:null}

                        </div>
                      </Grid>
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <Field
                            as={TextField}
                            size="small"
                            name="name"
                            label="Nome Identificador da Api"
                            variant="outlined"
                            placeholder="Envio de relatórios financeiros"
                            required
                            value={integration.name}
                            onChange={(e) => setIntegration({ ...integration, name: e.target.value })}
                            error={integration.name === ""}
                          />
                        </FormControl>
                      </Grid>

                      {integration.integrationType === "receiveTransferTicketEchat" && (
                        <>

                          <Grid item xs={12} sm={6} md={4}>
                            <FormControl variant="outlined" margin="dense" fullWidth>
                              <label>API URL</label>
                              <CopyToClipBoard text={process.env.REACT_APP_BACKEND_URL} />
                            </FormControl>
                          </Grid>


                        </>
                      )}
                      {integration.integrationType === "sendTransferTicketEchat" && (
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl variant="outlined" margin="dense" fullWidth>
                            <Field
                              as={TextField}
                              size="small"
                              name="apiUrl"
                              label="API URL"
                              variant="outlined"
                              placeholder="https://api.example.com"
                              required
                              value={integration.apiUrl}
                              onChange={(e) => setIntegration({ ...integration, apiUrl: e.target.value })}
                              error={integration.apiUrl === ""}
                            />
                          </FormControl>
                        </Grid>
                      )}
                      <Grid item xs={12} sm={6} md={4}>
                        <FormControl variant="outlined" margin="dense" fullWidth>
                          <Field
                            as={TextField}
                            size="small"
                            name="apiToken"
                            label="API Token"
                            variant="outlined"
                            placeholder="Token"
                            required
                            value={integration.apiToken}
                            onChange={(e) => setIntegration({ ...integration, apiToken: e.target.value })}
                            error={integration.apiToken === ""}
                          />
                        </FormControl>
                      </Grid>
                      {integration.integrationType === "sendTransferTicketEchat" || integration.integrationType === "receiveTransferTicketEchat" ? <>
                        <Grid item xs={12} sm={6} md={4}>
                          <FormControl variant="outlined" margin="dense" fullWidth>
                            <Field
                              multiline
                              minRows={5}
                              as={TextField}
                              size="small"
                              name="message"
                              label="Mensagem"
                              variant="outlined"
                              placeholder="Mensagem"

                              //required
                              value={integration.message}
                              onChange={(e) => setIntegration({ ...integration, message: e.target.value })}
                            // error={integration.message === ""}
                            />
                          </FormControl>
                        </Grid>
                      </> : null}

                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="secondary" disabled={isSubmitting} variant="outlined">
                      {i18n.t("common.cancel")}
                    </Button>
                    <Button
                      type="submit" color="primary" variant="contained">
                      {apiId ? i18n.t("common.save") : i18n.t("common.add")}
                      {isSubmitting && <CircularProgress size={24} />}
                    </Button>
                  </DialogActions>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </Dialog>
    </div>
  );
};

export default IntegrationModal;