import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Typography,
    Card,
    CardContent,
    CardActions
} from "@material-ui/core";
import { SendOutlined } from "@material-ui/icons";
import api from '../../services/api';

const ConfirmSendModal = ({ open, onClose, onSend, location, ticketId , quotedMsg }) => {
    const handleSend = async () => {

        const message = {
            locationId: location.id,
            ticketId: ticketId,
            quotedMsg: quotedMsg
        }

        try {
            const { data } = await api.post(`/messages/${ticketId}`, message);
            onSend();
            onClose();
        } catch (err) {
            console.log(err);
        }

    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Confirmar Envio</DialogTitle>
            <DialogContent>
                <Card>
                    <CardContent>
                        <Typography variant="h6" component="h2">
                            {location.name}
                        </Typography>
                        <iframe
                            src={`https://maps.google.com/maps?q=${location.latitude},${location.longitude}&z=15&output=embed`}
                            width="100%"
                            height="200px"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen
                        ></iframe>
                    </CardContent>
                </Card>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSend} color="primary" variant="contained" startIcon={<SendOutlined />}>
                    Enviar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmSendModal;