import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import { InputLabel, MenuItem, Select } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
}));


const SelectGeneric = ({ label, route, value, onChange, required, autoFocus, ...rest }) => {
  const classes = useStyles();
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        setLoading(true)
        const { data } = await api.get(`/${route}`);
        setOptions(data);
      } catch (err) {
        toastError(err);
      }
      setLoading(false)
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (value) {
          setSelected(value);
        }
      } catch (err) {
        toastError(err);
      }
    })();
  }, [value]);

useEffect(()=>{
  onChange(selected)
},[selected])





  return (
    <div >
      {!loading ?
        <FormControl {...rest} variant="outlined" className={classes.maxWidth}>
          <InputLabel>{i18n.t(label)}</InputLabel>
          <Select
            value={selected}
            onChange={(e) => {
              setSelected(e.target.value)
            }}
            label={i18n.t(label)}
          >
            <MenuItem value={''}>&nbsp;</MenuItem>
            {options.map((option) => (
              <MenuItem key={option.id} value={option.id}>{option.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        : <></>}

    </div>
  );
};

export default SelectGeneric;
