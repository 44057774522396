import React, { useEffect, useState } from "react";
import { makeStyles, Paper } from "@material-ui/core";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";
import api from "../../services/api";
import MenuApi from "../../components/MenuApi";
import "swagger-ui-react/swagger-ui.css"
import SwaggerUI from "swagger-ui-react";
import Loading from "../../components/Loading";
const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  customTableCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const Api = () => {
  const classes = useStyles();
  const [jsonData, setJsonData] = useState(null);

  useEffect(() => {
    (async () => {
      try {
        const { data } = await api.get("/api/documents");
        setJsonData(data);
      } catch (err) {
        console.log(err)
        toastError(err);
      }
    })();
  }, []);

  return (
    <>
      {jsonData ? (
        <MainContainer>
          <MainHeader>
            <Title>{i18n.t("api.title")}</Title>
            <MainHeaderButtonsWrapper>
              <MenuApi />
            </MainHeaderButtonsWrapper>
          </MainHeader>
          <Paper className={classes.mainPaper} variant="outlined">
            <SwaggerUI spec={jsonData} />
          </Paper>
        </MainContainer>
      ) : (
        <Loading size={40} />
      )}
    </>
  );
};

export default Api;