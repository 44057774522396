import React, { useState } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import CloseIcon from '@material-ui/icons/Close'; // Importando o ícone de fechar

const InfoBadge = ({ infoText, modalTitle, modalContent }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <span>
      <Tooltip title={infoText} arrow>
        <IconButton onClick={handleOpen}>
          <HelpOutlineIcon />
        </IconButton>
      </Tooltip>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {modalTitle}
          <IconButton
            aria-label="close"
            onClick={handleClose}
            style={{ position: 'absolute', right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {modalContent}
        </DialogContent>
      </Dialog>
    </span>
  );
};

export default InfoBadge;