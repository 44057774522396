import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import { useLocalStorage } from "../../hooks/useLocalStorage";
import { Checkbox, FormControl, IconButton, MenuItem, Menu, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Card } from "@material-ui/core";
import Brightness7Icon from '@material-ui/icons/Brightness7';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import MainHeader from "../../components/MainHeader";
import Title from "../../components/Title";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import { Tooltip } from "@mui/material";
import api from "../../services/api";
import openSocket from "socket.io-client";
import TablePagination from '@mui/material/TablePagination';
import { minutes } from "../../helpers/commonConsts";
import { useQuery, useQueryClient } from 'react-query'
import _ from 'lodash'
const moment = require('moment/moment');



const useStyles = makeStyles(theme => ({
    root: {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
    },
    dialog: {
        /* overflowY: "scroll",
        ...theme.scrollbarStyles, */
    },
    table: {
        minWidth: 650,
        padding: 0

    },
    tableTitles: {
        fontSize: 26
    },
    tableTitle: {

        fontWeight: "bold"
    },
    tableBorder: {

        padding: 0,
        border: "1px solid gray"
    }

}));



const convertForHours = (milliseconds) => {
    if (isNaN(milliseconds)) {
        return "00:00:00";
    } else {
        const dur = moment.duration(milliseconds, 'milliseconds');
        const horas = moment.utc(dur.asMilliseconds()).format(" HH:mm:ss");
        const days = dur.days();
        const result = ((days === 1 ? days + " dia " : "") || (days > 1 ? days + " dias " : "")) + horas
        return result;
    }
}



const Monitor = () => {
    const history = useHistory();
    const classes = useStyles();
    const clientTimestampDife = localStorage.getItem("clientTimestampDife") || 0
    const [theme, setTheme] = useLocalStorage("monitorThemeOption", "dark");
    const [fontSize, setFontSize] = useLocalStorage("themeFontSize", 18);
    const [automaticPageSeconds, setAutomaticPageSeconds] = useLocalStorage("automaticPageSeconds", 0);
    const [automaticPageSecondsAtendentes, setAutomaticPageSecondsAtendentes] = useLocalStorage("automaticPageSecondsAtendentes", 0);
    const primaryColor = theme === "dark" ? "#2F73E0" : "black"
    const primaryBackgroundColor = theme === "dark" ? "#111111" : "#e5ddd5"
    const [checkedItems, setCheckedItems] = useLocalStorage("monitorThemeSelect", ["ticketsHoje", "cargaAtendentes", "cargaFilas", "ticketsAbertos", "offlineUser"])
    const [offlineUser, setOfflineUser] = useLocalStorage("offlineUser", true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [menuOpen, setMenuOpen] = useState(false);
    const [ticketsOpen, setTicketsOpen] = useState([]);
    const [ticketsPending, setTicketsPending] = useState([]);
    const [ticketsClosed, setTicketsClosed] = useState([]);
    const [ticketsAgrupadosPorUser, setTicketsAgrupadosPorUser] = useState([]);
    const [ticketsAgrupadosPorUserSemFiltro, setTicketsAgrupadosPorUserSemFiltro] = useState([]);
    const [ticketsAgrupadosPorFila, setTicketsAgrupadosPorFila] = useState([]);
    const [localTime, setLocalTime] = useState(new Date().getTime() - clientTimestampDife)
    const [page, setPage] = React.useState(0);
    const [pageAtendente, setPageAtendente] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = useLocalStorage("rowsPerPage", 5);
    const [rowsPerPageAtendente, setRowsPerPageAtendente] = useLocalStorage("rowsPerPageAtendente", 5);
    const [tempoMaxAguardandoAtendimentoEmFila, setTempoMaxAguardandoAtendimentoEmFila] = useState(null);
    const [tempoMaxAguardandoPrimeiraReposta, setTempoMaxAguardandoPrimeiraReposta] = useState(null);
    const [tempoMedioPr, setTempoMedioPr] = useState(null);
    const [tempoMedioEspera, setTempoMedioEspera] = useState(null);
    const [tempoMedioAtendimento, setTempoMedioAtendimento] = useState(null);
    const [displayedRowsTicketsUsers, setDisplayedRowsTicketsUsers] = useState([]);
    const [displayedRowsTicketsOpen, setDisplayedDisplayedRowsTicketsOpen] = useState([]);

    //  const displayedRowsTicketsUsers = ticketsAgrupadosPorUser.slice(pageAtendente * rowsPerPageAtendente, pageAtendente * rowsPerPageAtendente + rowsPerPageAtendente);
    const [loading, setLoading] = useState(false);
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangePageAtendente = (event, newPage) => {
        setPageAtendente(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeRowsPerPageAtendente = (event) => {
        setRowsPerPageAtendente(parseInt(event.target.value, 10));
        setPageAtendente(0);
    };

    const { data: tickets, isFetching } = useQuery('tickets', async () => {
        setLoading(true)
        const { data } = await api.get("/monitor/", {
        });
        setLoading(false)
        return data
    },
        { refetchOnWindowFocus: false })


    const { data: users, isFetching: isFetchingUsers } = useQuery('users', async () => {
        setLoading(true)
        const { data } = await api.get("/usersOnline/", {


        });
        setLoading(false)
        return data
    },
        { refetchOnWindowFocus: false })


    const queryClient = useQueryClient()
    async function handleUpdateTicketState(ticket) {
        const previous = queryClient.getQueryData('tickets')
        let previousData = []
        if (previous) {
            previousData = previous;

        }
        if (previousData) {
            const ticketsEncontrados = previousData.filter(t => {
                return t.id === ticket.id
            });
            if (ticketsEncontrados?.length > 0) {
                previousData = previousData.map(t => {


                    if (t.id === ticket.id) { // se ja existe o ticket na lista atualiza
                        return ticket
                    } else {
                        return t
                    }
                })


                queryClient.setQueriesData('tickets', previousData)

            } else { // se não existe o ticket na lista adiciona
                previousData.unshift(ticket)
                queryClient.setQueriesData('tickets', previousData)

            }
        }

    }

    const updateUserList = (newUser) => {
        let users = queryClient.getQueryData('users')
        // Verifica se o usuário já existe na lista
        const existingUserIndex = users.findIndex(user => user.id === newUser.id);

        // Se o usuário existir na lista
        if (existingUserIndex !== -1) {
            // Se o novo status for 'online', substitui os dados do usuário na lista
            if (newUser.userStatus === 'online') {
                users[existingUserIndex] = newUser;
            }
            // Se o novo status não for 'online', remove o usuário da lista
            else {
                users.splice(existingUserIndex, 1);
            }
        }
        // Se o usuário não existir na lista e o status for 'online', adiciona o novo usuário à lista
        else if (newUser.userStatus === 'online') {
            users.push(newUser);
        }

        queryClient.setQueriesData('users', users)
    }

    // queryClient.fetchQuery('users')

    useEffect(() => {
        const interval = setInterval(() => {
            queryClient.fetchQuery('users')
        }, 1000 * 60 * 5);
        return () => clearInterval(interval);
    }, []);




    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
        socket.on("connect", () => {
            socket.emit("joinNotification")
            socket.emit("joinTickets", "open");
            socket.emit("joinTickets", "pending");
            socket.emit("joinTickets", "closed");
        });
        socket.on("ticket", data => {
            if (data.action === "update") {
                if (data?.ticket?.source === 2) {
                    handleUpdateTicketState(data.ticket)
                }

            }

        });
        socket.on("user", data => {
            if (data.action === "update") {
                updateUserList(data?.user)
            }

        });


        return () => {
            socket.disconnect();
        };
    }, []);



    const getUserStatus = (lastPresence) => {

        if (lastPresence) {
            let st = "offline"
            let sTime = new Date().getTime() - new Date(lastPresence).getTime()
            let time = 1000 * 60 * 10 // se o a ultima confirmação de presença é 10 minutos atrás marca como online
            if (sTime < time) {
                st = "online"
            }
            else {
                st = "offline a " + convertForHours(sTime)
            }
            return st
        }
        return "offline"

    }


    useEffect(() => {
        if (tickets) { // inicio agrupamento de tickets por Usuário

            const sortedTickets = tickets?.sort((a, b) => {
                return new Date(b.createdAt) - new Date(a.createdAt)
            });
            const ticketsAgrupadosPorUserName = sortedTickets.filter(t => t.userId).reduce((acc, ticket) => {
                const userName = ticket?.user?.name;
                const userStatus = getUserStatus(ticket?.user?.lastPresence)
                const status = ticket.status;
                const firstHumanInteraction = ticket.firstHumanInteraction
                const messages = ticket.messages
                if (!acc[userName]) {
                    acc[userName] = { userName, userStatus, open: 0, closed: 0, tpr: 0, tr: 0, iTpr: 0, iTr: 0, createdAt: ticket.createdAt };
                }
                if (firstHumanInteraction) {
                    acc[userName].iTpr++;
                    acc[userName].tpr += new Date(firstHumanInteraction).getTime() - new Date(ticket.createdAt).getTime()
                    const sortedMEssages = messages?.filter(m => m.fromMe).sort((a, b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt)
                    });
                    acc[userName].tr += (new Date(sortedMEssages?.length ? sortedMEssages[0]?.createdAt : ticket?.updatedAt).getTime() - new Date(ticket?.createdAt).getTime())
                    acc[userName].iTr += sortedMEssages?.length
                }
                if (status === 'open') {
                    acc[userName].open++;
                } else if (status === 'closed') {
                    acc[userName].closed++;
                }
                return acc;
            }, {});

            let arrayTicketsAgrupadosPorUser = [];

            for (const userName in ticketsAgrupadosPorUserName) {
                if (Object.hasOwnProperty.call(ticketsAgrupadosPorUserName, userName)) {
                    const ticket = ticketsAgrupadosPorUserName[userName];
                    arrayTicketsAgrupadosPorUser.push({
                        userName: userName,
                        userStatus: ticket.userStatus,
                        open: ticket.open,
                        closed: ticket.closed,
                        iTpr: ticket.iTpr,
                        tpr: ticket.tpr,
                        tMpr: ticket.tpr / ticket.iTpr,
                        iTr: ticket.iTr,
                        tr: ticket.tr,
                        tmr: ticket.tr / ticket.iTr,
                    });
                }
            }
            setTicketsAgrupadosPorUserSemFiltro(arrayTicketsAgrupadosPorUser)

            // final agrupamento de tickets por Usuário
            // inicio agrupamento de tickets por Fila

            const ticketsAgrupadosPorUserFila = tickets.filter(t => t.queueId).reduce((acc, ticket) => {
                const queueName = ticket?.queue?.name;
                const status = ticket.status;
                const firstHumanInteraction = ticket.firstHumanInteraction
                const messages = ticket.messages
                if (!acc[queueName]) {
                    acc[queueName] = { queueName, open: 0, closed: 0, tpr: 0, tr: 0, iTpr: 0, iTr: 0 };
                }
                if (firstHumanInteraction) {
                    acc[queueName].iTpr++;
                    acc[queueName].tpr += new Date(firstHumanInteraction).getTime() - new Date(ticket.createdAt).getTime()
                    const sortedMEssages = messages?.filter(m => m.fromMe).sort((a, b) => {
                        return new Date(b.createdAt) - new Date(a.createdAt)
                    });
                    acc[queueName].tr += (new Date(sortedMEssages?.length ? sortedMEssages[0]?.createdAt : ticket?.updatedAt).getTime() - new Date(ticket.createdAt).getTime())
                    acc[queueName].iTr += sortedMEssages?.length

                }
                if (status === 'open') {
                    acc[queueName].open++;
                } else if (status === 'closed') {
                    acc[queueName].closed++;
                }
                return acc;
            }, {});

            let arrayTicketsAgrupadosPorQueue = [];

            for (const queueName in ticketsAgrupadosPorUserFila) {
                if (Object.hasOwnProperty.call(ticketsAgrupadosPorUserFila, queueName)) {
                    const ticket = ticketsAgrupadosPorUserFila[queueName];
                    arrayTicketsAgrupadosPorQueue.push({
                        queueName: queueName,
                        open: ticket.open,
                        closed: ticket.closed,
                        iTpr: ticket.iTpr,
                        tpr: ticket.tpr,
                        tMpr: ticket.tpr / ticket.iTpr,
                        iTr: ticket.iTr,
                        tr: ticket.tr,
                        tmr: ticket.tr / ticket.iTr,
                    });
                }
            }

            setTicketsAgrupadosPorFila(arrayTicketsAgrupadosPorQueue)

            // final agrupamento de tickets por Fila
            let openTickets = tickets.filter(t => t.status === "open").sort((a, b) => a?.user?.name?.localeCompare(b?.user?.name));
            setTicketsOpen(openTickets)
            const closedTickets = tickets.filter(t => t.status === "closed")
            setTicketsClosed(closedTickets)
            const pendingTickets = tickets.filter(t => t.status === "pending")
            setTicketsPending(pendingTickets)
        }
    }, [tickets, users]);

    useEffect(() => {
        setDisplayedRowsTicketsUsers(ticketsAgrupadosPorUser.slice(pageAtendente * rowsPerPageAtendente, pageAtendente * rowsPerPageAtendente + rowsPerPageAtendente))
    }, [ticketsAgrupadosPorUser, pageAtendente, rowsPerPageAtendente]);

    useEffect(() => {
        setDisplayedDisplayedRowsTicketsOpen(ticketsOpen.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage))
    }, [ticketsOpen, page]);

    useEffect(() => {
        if (offlineUser) {
            setTicketsAgrupadosPorUser(ticketsAgrupadosPorUserSemFiltro)

        } else {
            setTicketsAgrupadosPorUser(ticketsAgrupadosPorUserSemFiltro.filter(u => u.userStatus === "online")) //..filter(t => t.user.userStatus === "online")
        }

    }, [offlineUser, ticketsOpen, tickets]);







    useEffect(() => {
        const interval = setInterval(() => {
            setLocalTime(new Date().getTime() - clientTimestampDife)
        }, 2000);
        return () => clearInterval(interval);
    }, []);



    useEffect(() => {
        if (tickets?.length) {
            let iPr = 0
            let msPr = 0
            let iTme = 0
            let msTme = 0
            let iTma = 0
            let msTma = 0
            for (const ticket of tickets) {
                if (ticket?.firstHumanInteraction) { // calcula o tempo Medio de Primeira Resposta
                    msPr += new Date(ticket?.firstHumanInteraction).getTime() - new Date(ticket?.createdAt).getTime()
                    iPr++
                }
                if (ticket?.firstHumanInteraction && ticket?.arrivedInQueue) {
                    msTme += new Date(ticket?.firstHumanInteraction).getTime() - new Date(ticket?.arrivedInQueue).getTime()
                    iTme++
                }
                if (ticket?.status === "closed") {
                    msTma += new Date(ticket?.updatedAt).getTime() - new Date(ticket?.createdAt).getTime()
                    iTma++
                }
            }
            setTempoMedioPr(convertForHours(msPr / iPr)) //seta o tempo Medio de Primeira Resposta
            setTempoMedioEspera(convertForHours(msTme / iTme)) //seta o tempo Medio de Espera
            setTempoMedioAtendimento(convertForHours(msTma / iTma)) //seta o tempo Medio de Atendimento
        }
        if (ticketsPending.length) {
            let tsPq = ticketsPending.filter(t => t.queueId)
            tsPq.sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt)
            });
            let tempoMaximoAguardandoAtendimentoEmFila = tsPq[0]?.createdAt ? convertForHours(localTime - new Date(tsPq[0]?.createdAt || null).getTime()) : null
            setTempoMaxAguardandoAtendimentoEmFila(tempoMaximoAguardandoAtendimentoEmFila)

        }
        if (ticketsOpen.length) {
            let tsOpen = ticketsOpen.filter(t => t.queueId && !t.firstHumanInteraction)
            tsOpen.sort((a, b) => {
                return new Date(a.createdAt) - new Date(b.createdAt)
            });
            let tempoMaximoAguardandoPrimeiraReposta = tsOpen[0]?.createdAt ? convertForHours(localTime - new Date(tsOpen[0]?.createdAt || null).getTime()) : null
            setTempoMaxAguardandoPrimeiraReposta(tempoMaximoAguardandoPrimeiraReposta)
        }


    }, [tickets, localTime]);

    useEffect(() => {
        if (automaticPageSeconds != 0) {
            const intervalId = setInterval(() => {
                const nextPage = page + 1 < Math.ceil(ticketsOpen.length / rowsPerPage) ? page + 1 : 0;
                setPage(nextPage);
            }, automaticPageSeconds * 1000);

            return () => clearInterval(intervalId);
        }

    }, [page, automaticPageSeconds, ticketsOpen.length, rowsPerPage]);

    useEffect(() => {
        if (automaticPageSecondsAtendentes != 0) {
            const intervalId = setInterval(() => {
                const nextPage = pageAtendente + 1 < Math.ceil(ticketsAgrupadosPorUser.length / rowsPerPageAtendente) ? pageAtendente + 1 : 0;
                setPageAtendente(nextPage);
            }, automaticPageSecondsAtendentes * 1000);
            return () => clearInterval(intervalId);
        }

    }, [pageAtendente, automaticPageSecondsAtendentes, ticketsAgrupadosPorUser.length, rowsPerPageAtendente]);






    const handleOpenMenuClick = (event) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true)
    };

    const handleMenuItemClick = () => {
        setMenuOpen(false);
    };

    const handleCheckboxChange = (value) => {
        if (checkedItems.includes(value)) {

            setCheckedItems(checkedItems.filter((item) => item !== value));
        } else {
            setCheckedItems([...checkedItems, value]);
        }
    };
    const handleTheme = () => {
        setTheme(
            theme === "dark" ? "white" : "dark"
        )
    }

    const handleFontSize = (sinal) => {

        if (sinal === "+" && fontSize < 34) {
            setFontSize(
                fontSize + 1
            )
        } else
            if (fontSize > 8) {
                setFontSize(
                    fontSize - 1
                )
            }


    }
    const aguardando = (ticket) => {
        let messages = ticket.messages.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        const lastMessage = messages[0]
        const persona = lastMessage?.fromMe === true ? ticket?.contact?.name : ticket?.user?.name
        const color = lastMessage?.fromMe === true ? "#4caf50" : "#f4aa2f"
        const aguardando = lastMessage?.createdAt ? convertForHours(localTime - new Date(lastMessage?.createdAt).getTime()) : "00:00:00"

        if (persona === null) return ""
        let length = 10
        let positionVisible = "start"
        let result = ""
        if (persona) {
            if (persona.length > length) {
                if (positionVisible === "start") {
                    result = persona.substring(0, length) + "..."
                } else
                    if (positionVisible === "end") {
                        result = "..." + persona.substring(persona.length - length, persona.length)
                    }

            } else
                result = persona


        }

        const displayButton = ` ${result} | ${aguardando}`
        const textMessage = `${lastMessage?.fromMe === true ? "Aguardando reposta do contato " : "Aguardando reposta do atendente"}  ${persona} a ${aguardando} s`
        // return { color, textMessage, displayButton }
        return (
            <>
                <Tooltip title={textMessage || ""} >
                    <button
                        style={{
                            fontSize: fontSize - (fontSize * 0.1), color: primaryColor,
                            backgroundColor: primaryBackgroundColor, border: "solid 3px",
                            padding: "2px 4px 2px 4px", margin: 2, borderColor: color, borderRadius: 6
                        }} >{displayButton}</button>
                </Tooltip>
            </>
        )
    }

    const tmrCalculator = (ticket) => {
        const respostas = ticket.messages.filter(t => t.fromMe === true).sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt)
        });
        const ultimaResposta = respostas[0]
        if (ultimaResposta) {
            let tempo = new Date(ultimaResposta.createdAt) - new Date(ticket.createdAt)
            const tmr = convertForHours(new Date(tempo / respostas.length).getTime())
            return tmr
        } else {
            return "00:00:00"
        }


    }
    const countPerguntasRespostas = (ticket) => {
        if (!ticket) return
        const respostas = ticket?.messages.filter(t => t.fromMe === true)
        const perguntas = ticket?.messages.filter(t => t.fromMe != true)
        return `${perguntas.length} / ${respostas.length}`
    }




    return (
        <div className={classes.root}>
            <Dialog
                className={classes.dialog}

                open={true}
                PaperProps={{
                    style: { backgroundColor: primaryBackgroundColor, color: primaryColor, width: "100%", padding: 0 }
                }}

                fullScreen
                scroll="paper"
            >



                {<div style={{ width: "99%" }} >

                    <MainHeader >
                        <Title></Title>
                        <div>
                            <div style={{ textAlign: "center",cursor: "pointer", alignContent: "center", backgroundColor: "white", borderRadius: "12px", color: "#0a7fc7", display: "block", alignItems: "center", justifyContent: "center" }}
                            onClick={()=>history.go(-1)}
                            >
                                <img style={{ margin: 0 }} src="logo2.png" width="60px" alt="" />
                                <h4 style={{ margin: 0 }}>Echat</h4>
                            </div>
                            <div>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={menuOpen} // Defina como true para abrir o menu
                                    onClose={handleMenuItemClick}
                                    key={"menuSelect"}
                                >
                                    <MenuItem key={'ticketsHoje'}>
                                        <Checkbox
                                            key={'checkTicketsHoje'}
                                            checked={checkedItems.includes('ticketsHoje')}
                                            onChange={() => handleCheckboxChange('ticketsHoje')}
                                        />
                                        Status dos tickets de hoje
                                    </MenuItem>
                                    <MenuItem key={'cargaAtendentes'}>
                                        <>
                                            <Checkbox
                                                key={'checkCargaAtendentes'}
                                                checked={checkedItems.includes('cargaAtendentes')}
                                                onChange={() => handleCheckboxChange('cargaAtendentes')}
                                            />
                                            Carga de Atendentes
                                            {checkedItems.includes('cargaAtendentes') ?

                                                <>
                                                    <Checkbox
                                                        key={'checkTicketsHoje'}
                                                        checked={offlineUser}
                                                        onChange={() => {
                                                            setOfflineUser(!offlineUser)
                                                        }}
                                                    />
                                                    Exibir atendentes offline
                                                </>

                                                : <div style={{ width: 0 }}></div>}

                                        </>

                                    </MenuItem>
                                    <MenuItem key={'cargaFilas'}>
                                        <Checkbox
                                            key={'checkCargaFilas'}
                                            checked={checkedItems.includes('cargaFilas')}
                                            onChange={() => handleCheckboxChange('cargaFilas')}
                                        />
                                        Carga Das Filas
                                    </MenuItem>
                                    <MenuItem key={'ticketsAbertos'}>
                                        <Checkbox
                                            key={'checkTicketsAbertos'}
                                            checked={checkedItems.includes('ticketsAbertos')}
                                            onChange={() => handleCheckboxChange('ticketsAbertos')}
                                        />
                                        Tickets Abertos
                                    </MenuItem>
                                    {/* Adicione mais itens conforme necessário */}
                                </Menu>
                            </div>

                        </div>

                        <div style={{ margin: 6, width: "100%" }}>
                            {checkedItems.includes('ticketsHoje') ? <>

                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow className={classes.tableBorder} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, border: "1px solid gray" }}>
                                                <TableCell align="center" colSpan={8} className={classes.tableBorder}><span style={{ fontSize: fontSize, color: primaryColor }} >Status dos tickets de hoje</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableRow className={classes.tableBorder} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, border: "1px solid gray" }}>
                                            <TableCell className={classes.tableBorder} align="center">
                                                <Tooltip title="Tempo máximo de um contato aguardando após selecionar a fila que deseja atendimento">
                                                    <span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Tempo máximo aguardando atendimento em fila</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className={classes.tableBorder} align="center">
                                                <Tooltip title="Tempo máximo de um contato aguardando reposta após o atendente aceitar o ticket">
                                                    <span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Tempo máximo aguardando primeira resposta</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className={classes.tableBorder} align="center">
                                                <Tooltip title="Tempo médio da primeira respostas dos tickets em atendimento e fechados durante o dia.È levado em consideração somente a primeira resposta humana, repostas do bot não servem como base de calculo para este indicador. ">
                                                    <span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Tempo médio de primeira resposta</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className={classes.tableBorder} align="center">
                                                <Tooltip title="Tempo médio de atendimento é calculado desde o momento da criação do ticket até o encerramento.Só entra neste indicador tickets que estão encerrados no dia de hoje">
                                                    <span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Tempo médio de atendimento</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className={classes.tableBorder} align="center">
                                                <Tooltip title="Tempo médio de espera é calculado desde o momento que o ticket é colocado em uma fila 'Seja pelo operador ou seja pelo chatbot' até o momento que aceito por um atendente.">
                                                    <span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Tempo médio de espera</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Tickets Aguardando na Fila</span></TableCell>
                                            <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Tickets em atendimento</span></TableCell>
                                            <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Tickets Resolvidos</span></TableCell>
                                        </TableRow>
                                        <TableBody>
                                            <TableRow style={{ backgroundColor: primaryBackgroundColor, color: primaryColor }} key={"ticketStatus"}>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {tempoMaxAguardandoAtendimentoEmFila || "00:00:00"} </span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {tempoMaxAguardandoPrimeiraReposta || "00:00:00"} </span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{tempoMedioPr || "00:00:00"}</span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{tempoMedioAtendimento || "00:00:00"}</span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{tempoMedioEspera || "00:00:00"}</span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{ticketsPending?.length || 0}</span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{ticketsOpen?.length || 0}</span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{ticketsClosed?.length || 0}</span></TableCell>
                                            </TableRow>

                                        </TableBody>
                                    </Table>
                                </TableContainer>


                                <br />
                            </> : null}
                        </div>
                        <MainHeaderButtonsWrapper>


                            <Tooltip title="Menu de exibição">
                                <IconButton onClick={handleOpenMenuClick} >
                                    <MoreVertIcon style={{ color: primaryColor }} />
                                </IconButton>
                            </Tooltip>
                            <Tooltip title="Diminuir a fonte">
                                <IconButton onClick={() => handleFontSize("-")}>
                                    <ZoomOutIcon style={{ color: primaryColor }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Aumentar a fonte">
                                <IconButton onClick={() => handleFontSize("+")}>
                                    <ZoomInIcon style={{ color: primaryColor }} />
                                </IconButton>
                            </Tooltip>

                            <Tooltip title="Modo Escuro / Modo Claro">
                                <IconButton onClick={handleTheme}>
                                    <Brightness7Icon style={{ color: primaryColor }} />
                                </IconButton>
                            </Tooltip>
                        </MainHeaderButtonsWrapper>
                    </MainHeader>
                    <div style={{ display: "flex", marginLeft: 4, minWidth: "100%", alignContent: "center" }}>
                        {checkedItems.includes('cargaAtendentes') ? <>


                            <div
                                style={{ margin: 6, paddingBottom: 32, border: "solid 1px gray", borderRadius: 4 }}

                            >
                                <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                                    <div style={{ marginLeft: 10, color: primaryColor, width: '200px' }} >
                                        <FormControl style={{ color: primaryColor }} fullWidth margin="dense" variant="standard"
                                        >
                                            <div
                                                style={{ display: "flex", marginTop: -7 }}>
                                                <p>Paginação Automática</p>
                                                <select
                                                    style={{ color: primaryColor, backgroundColor: primaryBackgroundColor, border: "none", padding: 1 }}
                                                    id="automaticPageSecondsAtendentes"
                                                    value={automaticPageSecondsAtendentes}
                                                    onChange={
                                                        (e) => { setAutomaticPageSecondsAtendentes(+e.target.value) }
                                                    }>
                                                    {minutes.map(minute => (
                                                        <option style={{ color: primaryColor }} key={`m-${minute}`} value={parseInt(minute)}>
                                                            {minute + " s"}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </FormControl>

                                    </div>
                                    <div style={{ color: primaryColor, width: '500px' }} >
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 30, 40, 50, 75, 100]}
                                            labelRowsPerPage="Linhas por Página"
                                            labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                            style={{ color: primaryColor }}
                                            component="div"
                                            count={ticketsAgrupadosPorUser.length || 0}
                                            page={pageAtendente}
                                            onPageChange={handleChangePageAtendente}
                                            rowsPerPage={rowsPerPageAtendente}
                                            onRowsPerPageChange={handleChangeRowsPerPageAtendente}
                                        />


                                    </div>
                                </div>

                                <div style={{ width: "98%", marginLeft: 6 }}>
                                    <TableContainer component={Paper}>
                                        <Table className={classes.table} size="small" aria-label="a dense table">
                                            <TableHead>
                                                <TableRow className={classes.tableBorder} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, border: "1px solid gray" }}>
                                                    <TableCell align="center" colSpan={8} className={classes.tableBorder}><span style={{ fontSize: fontSize, color: primaryColor }} >Carga de atendentes</span></TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableRow className={classes.tableBorder} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, border: "1px solid gray" }}>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >Atendentes</span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >TMPR/TMR</span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >Em atendimento/ Resolvidos</span></TableCell>
                                                {/*  <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >Data Json</span></TableCell> */}

                                            </TableRow>
                                            <TableBody>

                                                {displayedRowsTicketsUsers.map((row) => (
                                                    <TableRow style={{ backgroundColor: primaryBackgroundColor, color: primaryColor }} key={row.userName}>
                                                        <TableCell className={classes.tableBorder} style={{ backgroundColor: users.find(user => user.name === row.userName) ? "green" : "orange" }} align="center">
                                                            <b style={{ fontSize: fontSize, color: primaryColor, backgroundColor: primaryBackgroundColor, paddingLeft: 3, paddingRight: 3, borderRadius: 4 }} > {row?.userName}
                                                            </b>  </TableCell>
                                                        <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {`${convertForHours(row?.tMpr)}/${convertForHours(row?.tmr)}`} </span></TableCell>
                                                        <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{`${row?.open} / ${row?.closed}`}</span></TableCell>
                                                        {/* <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{`${JSON.stringify(row , null , 2)}`}</span></TableCell> */}
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                </div>
                            </div>

                        </> : null}

                        {checkedItems.includes('cargaFilas') ? <>
                            <div
                                style={{ margin: 6, width: "50%", }}
                            >
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow className={classes.tableBorder} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, border: "1px solid gray" }}>
                                                <TableCell align="center" colSpan={8} className={classes.tableBorder}><span style={{ fontSize: fontSize, color: primaryColor }} >Carga das filas</span></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableRow className={classes.tableBorder} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, border: "1px solid gray" }}>
                                            <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >Fila</span></TableCell>
                                            <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >TMPR/TMR</span></TableCell>
                                            <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >Em atendimento/ Resolvidos</span></TableCell>
                                        </TableRow>
                                        <TableBody>
                                            {ticketsAgrupadosPorFila.map((row) => (
                                                <TableRow style={{ backgroundColor: primaryBackgroundColor, color: primaryColor }} key={row.queueName}>
                                                    <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {row.queueName} </span></TableCell>
                                                    <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {`${convertForHours(row?.tMpr)} / ${convertForHours(row?.tmr)}`}</span></TableCell>
                                                    <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {`${row?.open} / ${row?.closed}`}</span></TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                            </div>
                        </> : null}

                    </div>

                    {checkedItems.includes('ticketsAbertos') ? <>
                        <div
                            style={{ margin: 9, paddingBottom: 32, border: "solid 1px gray", borderRadius: 4 }}

                        >
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-evenly" }}>
                                <div style={{ color: primaryColor, width: '200px' }} >
                                    <FormControl style={{ color: primaryColor }} fullWidth margin="dense" variant="standard"
                                    >


                                        <div

                                            style={{ display: "flex", marginTop: -7 }}>
                                            <p>Paginação Automática</p>
                                            <select
                                                style={{ color: primaryColor, backgroundColor: primaryBackgroundColor, border: "none", padding: 1 }}
                                                id="secondsOfPagination"
                                                value={automaticPageSeconds}
                                                onChange={
                                                    (e) => { setAutomaticPageSeconds(+e.target.value) }
                                                }
                                            >

                                                {minutes.map(minute => (
                                                    <option style={{ color: primaryColor }} key={`m-${minute}`} value={parseInt(minute)}>
                                                        {minute + " s"}
                                                    </option>
                                                ))}
                                            </select>

                                        </div>
                                    </FormControl>
                                </div>
                                <div style={{ color: primaryColor, width: '500px' }} >
                                    <TablePagination
                                        rowsPerPageOptions={[5, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 30, 40, 50, 75, 100]}
                                        labelRowsPerPage="Linhas por Página"
                                        labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                                        style={{ color: primaryColor }}
                                        component="div"
                                        count={ticketsOpen.length || 0}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        rowsPerPage={rowsPerPage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </div>
                            </div>



                            <TableContainer component={Paper}>
                                <Table className={classes.table} size="small" aria-label="a dense table">
                                    <TableHead>
                                        <TableRow className={classes.tableBorder} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, border: "1px solid gray" }}>
                                            <TableCell align="center" colSpan={9} className={classes.tableBorder}><span style={{ fontSize: fontSize, color: primaryColor }} >Tickets abertos</span></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableRow className={classes.tableBorder} style={{ backgroundColor: primaryBackgroundColor, color: primaryColor, border: "1px solid gray" }}>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Contato</span></TableCell>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Fila</span></TableCell>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Atendente</span></TableCell>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} ><Tooltip title="Mensagens do contato / Mensagens do atendente"><span> Msgs :Contato / Atendente</span></Tooltip></span></TableCell>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >H. Abertura</span></TableCell>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >T. Aberto</span></TableCell>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >TPR</span></TableCell>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >TMR</span></TableCell>
                                        <TableCell className={classes.tableBorder} align="center"><span className={classes.tableTitle} style={{ fontSize: fontSize, color: primaryColor }} >Aguardando Resposta</span></TableCell>

                                    </TableRow>
                                    <TableBody>
                                        {displayedRowsTicketsOpen.map((row) => (
                                            <TableRow style={{ backgroundColor: primaryBackgroundColor, color: primaryColor }} key={row.id}>
                                                <TableCell className={classes.tableBorder} align="center">
                                                    <span style={{ fontSize: fontSize, color: primaryColor }} >
                                                        <a href={`/tickets/${row?.uuid}`} target="_blank"
                                                            style={{ color: "#0077cc", textDecoration: "none", onmousedown: "this.style.color='#0077cc'", onmouseup: "this.style.color='#0077cc'", onmouseout: "this.style.color='#0077cc'", onmousemove: "this.style.color='#004466'" }}>
                                                            {row?.contact?.name}</a>
                                                    </span>
                                                </TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {row?.queue?.name} </span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {row?.user?.name} </span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} > {countPerguntasRespostas(row)} </span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center">
                                                    <span style={{ fontSize: fontSize, color: primaryColor }} >
                                                        {moment(row?.createdAt).format("HH:mm")} h
                                                    </span>
                                                </TableCell>
                                                <TableCell className={classes.tableBorder} align="center">
                                                    <span style={{ fontSize: fontSize, color: primaryColor }} >
                                                        {row?.firstHumanInteraction ? (convertForHours(localTime - new Date(row.createdAt).getTime())) : "00:00:00"}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={classes.tableBorder} align="center">
                                                    <span style={{ fontSize: fontSize, color: primaryColor }} >
                                                        {row?.firstHumanInteraction ? (convertForHours(new Date(row?.firstHumanInteraction).getTime() - new Date(row.createdAt).getTime())) : "00:00:00"}
                                                    </span>
                                                </TableCell>
                                                <TableCell className={classes.tableBorder} align="center"><span style={{ fontSize: fontSize, color: primaryColor }} >{tmrCalculator(row)}</span></TableCell>
                                                <TableCell className={classes.tableBorder} align="center">
                                                    {aguardando(row)}
                                                </TableCell>

                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </div>
                    </> : null}

                </div>}


            </Dialog>
        </div>
    );
};

export default Monitor;