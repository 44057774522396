import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import CircularProgress from '@mui/material/CircularProgress';
import { debounce } from 'lodash'; // Importando apenas a função debounce de lodash
import api from '../../services/api';

const ContactAutocomplete = ({selectedContacts}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  

  const handleSearch = debounce(async (query) => {
    setLoading(true);
    try {
      const { data } = await api.get("/contacts/", {
        params: { searchParam: query },
      });
      setOptions(data?.contacts);
    } catch (error) {
      console.error('Erro ao buscar contatos:', error);
    } finally {
      setLoading(false);
    }
  }, 300);

  const handleChange = (event, value) => {
    setSearchQuery(value);
    //setSelectedContacts(value); // Atualiza o estado dos contatos selecionados
    handleSearch(value);
  };


  return (
    <Autocomplete
      multiple
      options={options}
      noOptionsText={"Digite para pesquisar"}
      //value={selectedContacts} // Define o valor selecionado
      getOptionLabel={(option) => option.name + " " + option.number}
      loading={loading}
      onChange={(e, newValue) => {
        selectedContacts(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Contatos"
          variant="outlined"
          onChange={(event) => handleChange(event, event.target.value)}
 
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default ContactAutocomplete;
