import React, { useState, } from "react";
import { Formik, Form } from "formik";
import { makeStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { FormControl } from "@material-ui/core";
import ContactAutocomplete from "./contactAutocomplete";
import SendIcon from "@material-ui/icons/Send";
import DialogTitle from "@material-ui/core/DialogTitle";
const useStyles = makeStyles(theme => ({
	forward: {
		display: "flex",
		flexWrap: "wrap",
	},
	multFieldLine: {
		display: "flex",
		"& > *:not(:last-child)": {
			marginRight: theme.spacing(1),
		},
	},

	btnWrapper: {
		position: "relative",
	},

	buttonProgress: {
		color: green[500],
		position: "absolute",
		top: "50%",
		left: "50%",
		marginTop: -12,
		marginLeft: -12,
	}
}));


const ContactSendVcardModal = ({ open, onClose, ticketId, quotedMsg }) => {
	const classes = useStyles();
	const [currentContacts, setCurrentContact] = useState([]);
	const [confirmOpen, setConfirmOpen] = useState(false);

	const handleClose = () => {
		onClose();
		setCurrentContact([]);
	};

	const handleForward = async () => {


		const message = {
			contacts: currentContacts,
			ticketId: ticketId,
			quotedMsg: quotedMsg
		}

		try {
			const { data } = await api.post(`/messages/${ticketId}`, message);

			onClose();
			setCurrentContact([]);
			setConfirmOpen(false);
		} catch (err) {
			console.log(err);
		}



	};

	const handleConfirmOpen = () => {
		setConfirmOpen(true);
	};

	const handleConfirmClose = () => {
		setConfirmOpen(false);
	};

	return (
		<div className={classes.forward}>
			<Dialog
				open={open}
				onClose={handleClose}
				maxWidth="xs"
				fullWidth
				scroll="paper"
			>
				<Formik
					enableReinitialize={true}
				>
					{({ isSubmitting }) => (
						<Form>
							<DialogContent dividers>
								<div className={classes.multFieldLine}>
									<FormControl
										variant="outlined"
										fullWidth
									>

										<ContactAutocomplete
											selectedContacts={e => setCurrentContact(e)}
										/>
									</FormControl>
								</div>
							</DialogContent>
							<DialogActions>

								<Button
									onClick={handleClose}
									color="secondary"
									disabled={isSubmitting}
									variant="outlined"
								>
									{i18n.t("scheduleModal.buttons.cancel")}
								</Button>

								{currentContacts.length > 0 ? (
									<Button
										color="primary"
										disabled={isSubmitting}
										variant="contained"
										className={classes.btnWrapper}
										onClick={handleConfirmOpen}
										endIcon={<SendIcon />}
									>
										Enviar
										{isSubmitting && (
											<CircularProgress
												size={24}
												className={classes.buttonProgress}
											/>
										)}
									</Button>
								) : <></>}

							</DialogActions>
						</Form>
					)}
				</Formik>
			</Dialog>

			<Dialog
				open={confirmOpen}
				onClose={handleConfirmClose}
				maxWidth="xs"
				fullWidth
			>
				<DialogTitle>Confirmação</DialogTitle>
				<DialogContent>
					Tem certeza que deseja enviar os contatos selecionados?
				</DialogContent>
				<DialogActions>
					<Button
						onClick={handleConfirmClose}
						color="secondary"
						variant="outlined"
					>
						Cancelar
					</Button>
					<Button
						onClick={handleForward}
						color="primary"
						variant="contained"
					>
						Confirmar
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};

export default ContactSendVcardModal;
