import React, { useState } from "react";
import { Tooltip } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const SecretDisplayed = (props) => {
  const [state, setState] = useState("Clique para copiar");
  const [showText, setShowText] = useState(false);
  const { text, secret } = props;

  const handleCopy = () => {
    setState("Copiado para area de transferência");
    navigator.clipboard.writeText(text);
  };

  const toggleVisibility = () => {
    setShowText(!showText);
  };

  return (
    <Tooltip
      style={{ cursor: "pointer" }}
      arrow
      placement="right"
      title={state}
    ><>
        <span onClick={handleCopy} id={text}>
          {secret ? (showText ? text : "******") : text}
        </span>
        {secret && (
          <span onClick={toggleVisibility} style={{ marginLeft: 8, cursor: "pointer" }}>
            {showText ? <VisibilityOff /> : <Visibility />}
          </span>
        )}

      </>

    </Tooltip>
  );
};

export default SecretDisplayed;