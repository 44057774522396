import React, { useState, useEffect, useReducer } from "react";
import {
  Button,
  IconButton,
  makeStyles,
  Paper,
  InputAdornment,
  TextField,
  Card,
  CardContent,
  CardActions,
  Typography,
} from "@material-ui/core";
import { Edit, DeleteOutline } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import MainContainer from "../../components/MainContainer";
import MainHeader from "../../components/MainHeader";
import MainHeaderButtonsWrapper from "../../components/MainHeaderButtonsWrapper";
import Title from "../../components/Title";
import api from "../../services/api";
import { i18n } from "../../translate/i18n";
import ConfirmationModal from "../../components/ConfirmationModal";
import { toast } from "react-toastify";
import toastError from "../../errors/toastError";

import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import LocationModal from "./modal";

const reducer = (state, action) => {
  if (action.type === "LOAD_DATA") {
    const locations = action.payload;
    const newLocations = [];

    locations.forEach((location) => {
      const locationIndex = state.findIndex((q) => q.id === location.id);
      if (locationIndex !== -1) {
        state[locationIndex] = location;
      } else {
        newLocations.push(location);
      }
    });

    return [...state, ...newLocations];
  }

  if (action.type === "UPDATE_FLOW") {
    const location = action.payload;
    const locationIndex = state.findIndex((q) => q.id === location.id);

    if (locationIndex !== -1) {
      state[locationIndex] = location;
      return [...state];
    } else {
      return [location, ...state];
    }
  }

  if (action.type === "DELETE_FLOW") {
    const locationId = action.payload;

    const locationIndex = state.findIndex((q) => q.id === locationId);
    if (locationIndex !== -1) {
      state.splice(locationIndex, 1);
    }
    return [...state];
  }

  if (action.type === "RESET") {
    return [];
  }
};

const useStyles = makeStyles((theme) => ({
  mainPaper: {
    flex: 1,
    padding: theme.spacing(1),
    overflowY: "scroll",
    ...theme.scrollbarStyles,
  },
  card: {
    margin: theme.spacing(2),
    width: 300,
  },
  cardContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
  },
}));

const Location = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchParam, setSearchParam] = useState("");
  const [locations, dispatch] = useReducer(reducer, []);
  const [deleting, setDeleting] = useState(null);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [locationId, setLocationId] = useState(null);
  const history = useHistory();
  const [locationModalOpen, setLocationModalOpen] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (id) {
      if (id === "new") {
        setLocationModalOpen(true);
        setLocationId(null);
      } else {
        setLocationId(id);
        setLocationModalOpen(true);
      }
      console.log("id", id);
    }
  }, [id]);

  useEffect(() => {
    dispatch({ type: "RESET" });
    setPageNumber(1);
  }, [searchParam]);

  const fetch = async () => {
    console.log("fetching  ia", );
    try {
      const { data } = await api.get("/locations/", {
        params: { searchParam, pageNumber },
      });
      console.log(data);
      dispatch({ type: "LOAD_DATA", payload: data.locations });
      setHasMore(data.hasMore);
      setLoading(false);
    } catch (err) {
      console.log(err);
      toastError(err);
    }
  };
  useEffect(() => {
    console.log("fetching");
    fetch();
  }, []);

  useEffect(() => {
    setLoading(true);
    const delayDebounceFn = setTimeout(() => {
      fetch();
    }, 500);
    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line
  }, [searchParam, pageNumber]);

  const handleSearch = (event) => {
    setSearchParam(event.target.value.toLowerCase());
  };

  const handleOpenModal = () => {
    setLocationModalOpen(true);
    fetch();
  };

  const handleEdit = (locationId) => {
    setLocationId(locationId);
    setLocationModalOpen(true);
  };

  const handleDelete = async (locationId) => {
    try {
      await api.delete(`/locations/${locationId}`);
      toast.success(i18n.t("location.toasts.deleted"));
    } catch (err) {
      toastError(err);
    }
    setDeleting(null);
    setSearchParam("");
    setPageNumber(1);
    setTimeout(() => {
      dispatch({ type: "RESET" });
      fetch();
    }, 1500);
  };

  const loadMore = () => {
    setPageNumber((prevState) => prevState + 1);
  };

  const handleCloseModal = () => {
    setLocationModalOpen(false);
    setLocationId(null);
    fetch();
    history.push(`/locations`);
  };

  const handleScroll = (e) => {
    if (!hasMore || loading) return;
    const { scrollTop, scrollHeight, clientHeight } = e.currentTarget;
    if (scrollHeight - (scrollTop + 100) < clientHeight) {
      loadMore();
    }
  };
  const handelLocationSavedData = async (e) => {
    await new Promise(resolve => setTimeout(resolve, 1000));
    if (e?.name) {
        setSearchParam(e.name.toLowerCase());
    }
};

  return (
    <MainContainer>
      <ConfirmationModal
        title={
          deleting &&
          `${i18n.t("location.confirmationModal.deleteTitle")} ${deleting.name}?`
        }
        open={confirmModalOpen}
        onClose={setConfirmModalOpen}
        onConfirm={() => handleDelete(deleting.id)}
      >
        {i18n.t("common.irreversible")}
      </ConfirmationModal>
      {locationModalOpen ? (
        <LocationModal
          open={locationModalOpen}
          onClose={handleCloseModal}
          locationId={locationId}
          locationSavedData={handelLocationSavedData}
        />
      ) : null}
      <MainHeader>
        <Title>{i18n.t("location.title")}</Title>
        <MainHeaderButtonsWrapper>
          <TextField
            placeholder={i18n.t("common.search")}
            type="search"
            value={searchParam}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ color: "gray" }} />
                </InputAdornment>
              ),
            }}
          />
          <Button variant="contained" color="primary" onClick={() => history.push(`/locations`)}>
            {i18n.t("common.goBack")}
          </Button>
          <Button variant="contained" color="primary" onClick={handleOpenModal}>
            {i18n.t("location.buttons.add")}
          </Button>
        </MainHeaderButtonsWrapper>
      </MainHeader>
      <Paper className={classes.mainPaper} variant="outlined" onScroll={handleScroll}>
        <div className={classes.cardContainer}>
          {locations.map((location) => (
            <Card key={location.id} className={classes.card}>
              <CardContent>
                <Typography variant="h6" component="h2">
                  {location.name}
                </Typography>

                <iframe
                  src={`https://maps.google.com/maps?q=${location.latitude},${location.longitude}&z=15&output=embed`}
                  width="100%"
                  height="200px"
                  frameBorder="0"
                  style={{ border: 0 }}
                  allowFullScreen
                ></iframe>
              </CardContent>
              <CardActions>
                <IconButton size="small" onClick={() => handleEdit(location.id)}>
                  <Edit />
                </IconButton>
                <IconButton
                  size="small"
                  onClick={() => {
                    setConfirmModalOpen(true);
                    setDeleting(location);
                  }}
                >
                  <DeleteOutline />
                </IconButton>
              </CardActions>
            </Card>
          ))}
          {loading && <Typography>Carregando...</Typography>}
        </div>
      </Paper>
    </MainContainer>
  );
};

export default Location;