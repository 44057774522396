import React, { useState, useRef, useEffect } from 'react';
import toastError from "../../errors/toastError";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import { toast } from "react-toastify";
import LocationOnMaps from '../../components/LocationOnMaps';
import InfoBadge from '../../components/InfoBadge';

const useStyles = makeStyles((theme) => ({
  dialog: {
    // the dropdown is next to the dialog root, not inside
    "& + .pac-container": {
      zIndex: theme.zIndex.modal + 1
    }
  }
}));

const LocationModal = ({ open, onClose, locationId , locationSavedData }) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [isDeleted, setIsDeleted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleMarkerChange = (marker) => {
    setLatitude(marker.lat);
    setLongitude(marker.lng);
    setName(marker.name);
  };




  useEffect(() => {
    const fetchLocation = async () => {
      setLoading(true);
      if (locationId) {
        try {
          const { data } = await api.get(`/locations/${locationId}`);
          setName(data.name);
          setLatitude(data.latitude ? parseFloat(data.latitude) : "");
          setLongitude(data.longitude ? parseFloat(data.longitude) : "");
        } catch (err) {
          toastError(err);
        }
      }
      setLoading(false);
    };
    fetchLocation();
  }, [locationId]);

  const handleSave = async () => {
    const locationData = { name, latitude, longitude, isDeleted };
    try {
      if (locationId) {
        await api.put(`/locations/${locationId}`, locationData);
        toast.success(i18n.t("location.toasts.updated"));
      } else {
        await api.post("/locations", locationData);
        locationSavedData(locationData);
        toast.success(i18n.t("location.toasts.created"));
      }
      onClose();
    } catch (err) {
      toastError(err);
    }
  };



  return (<>
    {(open && !loading) ? (
      <Dialog fullWidth maxWidth='md' open={open} onClose={onClose} className={classes.dialog}>
        <DialogTitle>
          Localização         <InfoBadge
            infoText="Clique para mais informações"
            modalTitle="Aqui estão as instruções detalhadas."
            modalContent={<>    
            <h3>Instruções para Uso do Mapa</h3>
              <ol>
                <li>Digite o nome da localização.</li>
                <li>Clique no mapa para definir a localização.</li>
                <li>Confira se a localização está correta.</li>
                <li>Amplie o mapa se necessário.</li>
                <li>O Ponto Vermelho é a localização exata.</li>
                <li>Clique no botão "Salvar" para salvar a localização.</li>
                <li>Clique no botão "Cancelar" para cancelar a edição da localização.</li>
              </ol></>}
          />
        </DialogTitle>

        <DialogContent dividers>

          <LocationOnMaps onChange={handleMarkerChange} onClose={onClose} initialMarker={locationId ? { lat: latitude, lng: longitude, name } : { lat: -12.9777378, lng: -38.5016363, name: "" }} />
          <TextField
            margin="dense"
            variant="outlined"
            label={i18n.t("location.modal.name")}
            type="text"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            {i18n.t("common.cancel")}
          </Button>
          <Button onClick={handleSave} color="primary">
            {i18n.t("common.save")}
          </Button>
        </DialogActions>
      </Dialog>
    ) : (
      null
    )}
  </>
  )
};

export default LocationModal;