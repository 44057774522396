import React, { useState, useRef, useEffect } from "react";
import { FormControl, TextField, Button, CircularProgress, makeStyles, Grid, Paper, Select, MenuItem } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SearchIcon from "@material-ui/icons/Search";
import { i18n } from "../../translate/i18n";

import moment from "moment";
import api from "../../services/api";
import { toast } from "react-toastify";
import { TagsFilter } from "../../components/TagsFilter";
const useStyles = makeStyles((theme) => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    searchInputWrapper: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 6,
    },
    searchItem: {
        flex: "1 1 300px",
        minWidth: 300,
        marginBottom: theme.spacing(2),
    },
}));


const SearchTickets = ({ handleSearchResults, handlePauseSocket , pauseSocket }) => {
    const classes = useStyles();
    const [searchParam, setSearchParam] = useState("");
    const [searchContact, setSearchContact] = useState("");
    const [searchUserParam, setSearchUserParam] = useState("");
    const [options, setOptions] = useState([]);
    const [userOptions, setUserOptions] = useState([]);
    const [searchContactId, setSelectedContact] = useState(null);
    const [loading, setLoading] = useState(false);
    const [loadingUser, setLoadingUser] = useState(false);
    const [searchUserId, setSelectedUser] = useState(null);
    const [searchInitialDate, setInitialDate] = useState(moment().add(-30, "days").format("YYYY-MM-DDTHH:mm"));
    const [searchFinalDate, setFinalDate] = useState(moment().add(0, "days").format("YYYY-MM-DDTHH:mm"));
    const [tags, setSelectedTags] = useState([]);
    const [ticketId, setTicketId] = useState("");
    const [ticketUUID, setTicketUUID] = useState("");
    const [status, setStatus] = useState("");
    const searchInputRef = useRef();
    

    useEffect(() => {
        if (searchUserParam.length < 3) {
            setLoadingUser(false);
            return;
        }
        setLoadingUser(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get("/users/", {
                        params: { searchParam: searchUserParam },
                    });
                    setUserOptions(data.users);
                    setLoadingUser(false);
                } catch (err) {
                    setLoadingUser(false);
                    toast.error("Não foi possível carregar os usuários");
                }
            };

            fetchUsers();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchUserParam]);

    useEffect(() => {
        if (searchContact.length < 3) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get("contacts", {
                        params: { searchParam: searchContact },
                    });
                    setOptions(data.contacts);
                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toast.error("Falha na busca de contatos");
                }
            };

            fetchContacts();
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchContact]);

    const handleSearch = () => {
        handleSearchResults({
            searchParam,
            tags,
            searchContactId,
            searchUserId,
            searchInitialDate,
            searchFinalDate,
            status, // Adiciona o status aos parâmetros de busca
            tab: "search",
            ticketId,
            ticketUUID
        });
    };

    const renderOption = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${i18n.t("newTicketModal.add")} ${option.name}`;
        }
    };

    const renderOptionLabel = (option) => {
        if (option.number) {
            return `${option.name} - ${option.number}`;
        } else {
            return `${option.name}`;
        }
    };

    return (
        <Paper>
            <Grid style={{ padding: 12 }} container spacing={2}>
                <Grid item xs={4} sm={3}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <TextField
                            variant="outlined"
                            size="small"
                            type="search"
                            placeholder="Buscar Textos"
                            onChange={e => setSearchParam(e.target.value.toLowerCase())}
                            inputRef={searchInputRef}
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={3}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <TextField
                            variant="outlined"
                            size="small"
                            type="search"
                            placeholder="Ticket Id"
                            onChange={e => setTicketId(e.target.value.toLowerCase())}
                            inputRef={searchInputRef}
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={3}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <TextField
                            variant="outlined"
                            size="small"
                            type="search"
                            placeholder="Ticket UUID"
                            onChange={e => setTicketUUID(e.target.value.toLowerCase())}
                            inputRef={searchInputRef}
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={3}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <TextField
                            variant="outlined"
                            size="small"
                            type="search"
                            placeholder="Buscar Textos"
                            onChange={e => setSearchParam(e.target.value.toLowerCase())}
                            inputRef={searchInputRef}
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={3}>
                    <div style={{ paddingTop: 8 }}>
                        <TagsFilter onFiltered={setSelectedTags} />
                    </div>

                </Grid>
                <Grid item xs={4} sm={3}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <Autocomplete
                            size="small"
                            fullWidth
                            options={options}
                            loading={loading}
                            clearOnBlur
                            autoHighlight
                            freeSolo
                            clearOnEscape
                            getOptionLabel={renderOptionLabel}
                            renderOption={renderOption}
                            onChange={(e, newValue) => setSelectedContact(newValue?.id || null)}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label={i18n.t("newTicketModal.fieldLabel")}
                                    variant="outlined"
                                    autoFocus
                                    onChange={(e) => setSearchContact(e.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={3}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <Autocomplete
                            size="small"
                            getOptionLabel={option => `${option.name}`}
                            onChange={(e, newValue) => setSelectedUser(newValue?.id || null)}
                            options={userOptions}
                            freeSolo
                            autoHighlight
                            noOptionsText={i18n.t("transferTicketModal.noOptions")}
                            loading={loadingUser}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label={i18n.t("transferTicketModal.fieldLabel")}
                                    variant="outlined"
                                    autoFocus
                                    onChange={e => setSearchUserParam(e.target.value)}
                                    InputProps={{
                                        ...params.InputProps,
                                        endAdornment: (
                                            <React.Fragment>
                                                {loadingUser ? <CircularProgress color="inherit" size={20} /> : null}
                                                {params.InputProps.endAdornment}
                                            </React.Fragment>
                                        ),
                                    }}
                                />
                            )}
                        />
                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={4}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <div style={{ display: "flex" }}>
                            <TextField
                                size="small"
                                style={{ marginRight: 6 }}
                                label={i18n.t("reports.searchInitialDate")}
                                type="datetime-local"
                                name="searchInitialDate"
                                variant="outlined"
                                fullWidth
                                value={moment(searchInitialDate).format("YYYY-MM-DDTHH:mm")}
                                onChange={e => setInitialDate(e.target.value)}
                            />
                            <TextField
                                size="small"
                                label={i18n.t("reports.searchFinalDate")}
                                type="datetime-local"
                                name="searchFinalDate"
                                variant="outlined"
                                fullWidth
                                value={moment(searchFinalDate).format("YYYY-MM-DDTHH:mm")}
                                onChange={e => setFinalDate(e.target.value)}
                            />
                        </div>
                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={4}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <Select
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                            displayEmpty
                            variant="outlined"
                            size="small"
                        >
                            <MenuItem value="">
                                <em>Selecione o Status</em>
                            </MenuItem>
                            <MenuItem value="npsOpen">Aberto NPS</MenuItem>
                            <MenuItem value="apiOpen">Aberto API</MenuItem>
                            <MenuItem value="open">Aberto</MenuItem>
                            <MenuItem value="pending">Pendente</MenuItem>
                            <MenuItem value="closed">Fechado</MenuItem>
                        </Select>
                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={2}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <Button
                            endIcon={<SearchIcon className={classes.searchIcon} />}
                            variant="contained"
                            color="primary"
                            onClick={handleSearch}
                        >
                            Buscar
                        </Button>

                    </FormControl>

                </Grid>
                <Grid item xs={4} sm={2}>
                    <FormControl fullWidth margin="dense" variant="outlined">
                        <Button
                            variant="contained"
                            color={pauseSocket ? "secondary" : "default"}
                            onClick={handlePauseSocket}
                        >
                            {pauseSocket ? "Continuar Realtime" : "Pausar Realtime"}
                        </Button>
                    </FormControl>

                </Grid>
            </Grid>
        </Paper>


    );
};

export default SearchTickets;